import {configureStore} from '@reduxjs/toolkit';
import authReducer from './authSlice';
import userRegisterReducer from './userRegisterSlice';
import coachRegisterReducer from './coachRegisterSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        userRegister: userRegisterReducer,
        coachRegister: coachRegisterReducer,
    },
});



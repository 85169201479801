import React from 'react';
import {Col, DatePicker, Row, TagPicker} from "rsuite";
import {isBefore} from "rsuite/cjs/internals/utils/date";
import './styles.css';


const CoachSelector = props => {
    const dataEspecialidad = [{
        label: 'Entrenador Personal', value: 'Entrenador Personal',
    }, {
        label: 'Nutricionista', value: 'Nutricionista',
    }, {
        label: 'Psicologo', value: 'Psicologo',
    },];

    const dataLocalidad = [{
        label: 'Bogota', value: 'Bogota',
    }, {
        label: 'Medellin', value: 'Medellin',
    }, {
        label: 'Cali', value: 'Cali',
    },];

    const dataCitas = [{
        label: 'Presencial', value: 'Presencial',
    }, {
        label: 'Virtual', value: 'Virtual',
    },];

    return (<div className='coach-selector-container'>
        <Row className='coach-selector-row'>
            <Col>
                <p>Especialidad</p>
                <TagPicker className='coach-selector-tag-picker' size="md" placeholder="Selecciona Especialidad"
                           data={dataEspecialidad}/>
            </Col>

            <Col>
                <p>Ubicación</p>
                <TagPicker className='coach-selector-tag-picker' size="md" placeholder="Selecciona Ubicacion"
                           data={dataLocalidad}/>
            </Col>
            <Col>
                <p>Tipo de Cita</p>
                <TagPicker className='coach-selector-tag-picker' size="md" placeholder="Selecciona Tipo"
                           data={dataCitas}/>
            </Col>
            <Col>
                <p>Disponibilidad</p>
                <DatePicker defaultValue={new Date()} shouldDisableDate={date => isBefore(date, new Date())}/>
            </Col>

        </Row>

    </div>);
};

CoachSelector.propTypes = {};

export default CoachSelector;
import React, {useEffect, useState} from 'react';
import {Container, Drawer, IconButton, Nav, Navbar} from 'rsuite';
import {BicepsFlexed, Menu as MenuIcon} from 'lucide-react';
import {useNavigate} from 'react-router-dom';
import {logout} from "../../store/authSlice";
import {useDispatch} from "react-redux";


const UnauthenticatedDesktopNavbar = ({activeKey, setActiveKey}) => {
    const navigate = useNavigate();

    return (<Navbar appearance="subtle" className="custom-navbar">
        <Navbar.Brand className="custom-navbar-brand" onClick={() => navigate('/')}>
            <div className="entrenalo-logo">
                <BicepsFlexed/>
                <span className="texto-entrenalo">ENTRENALO</span>
            </div>
        </Navbar.Brand>
        <Nav className="custom-nav" onSelect={setActiveKey} activeKey={activeKey}>
            <Nav.Item eventKey="1" onClick={() => navigate('/encuentra-tu-coach')}>Coaches</Nav.Item>
            <Nav.Item eventKey="2">Pricing</Nav.Item>
            <Nav.Menu title="Solutions">
                <Nav.Item eventKey="3">AI Apps</Nav.Item>
                <Nav.Item eventKey="4">Composable Commerce</Nav.Item>
            </Nav.Menu>
            <Nav.Menu title="Colabora">
                <Nav.Item eventKey="5" onClick={() => navigate('/registrarse-coach')}>Registro de Coach</Nav.Item>
                <Nav.Item eventKey="6">Contact Us</Nav.Item>
            </Nav.Menu>
        </Nav>
        <Nav className="custom-nav-right" pullRight>
            <Nav.Item onClick={() => navigate('/registrarse')}>Regístrate</Nav.Item>
            <Nav.Item onClick={() => navigate('/login')}>Iniciar Sesión</Nav.Item>
        </Nav>
    </Navbar>);
};

const UnauthenticatedMobileNavbar = ({onMenuClick}) => {
    const navigate = useNavigate();

    return (<Navbar appearance="subtle" className="mobile-navbar">
        <div className="mobile-navbar-content">
            <Navbar.Brand
                className="mobile-navbar-brand"
                onClick={() => navigate('/')}>
                <div className="entrenalo-logo">
                    <BicepsFlexed/>
                    <span className="texto-entrenalo">ENTRENALO</span>
                </div>
            </Navbar.Brand>
            <IconButton
                icon={<MenuIcon/>}
                className="menu-icon-button"
                onClick={onMenuClick}
            />
        </div>
    </Navbar>);
};

const UnauthenticatedMobileDrawer = ({showDrawer, onCloseDrawer, navigate}) => (<Drawer
    size="xs"
    className="drawer"
    placement="right"
    open={showDrawer}
    onClose={onCloseDrawer}
>
    <Drawer.Header>
        <Drawer.Title>Menú</Drawer.Title>
    </Drawer.Header>
    <Drawer.Body className="drawer-body">
        <Nav vertical>
            <Nav.Item onClick={() => {
                navigate('/encuentra-tu-coach');
                onCloseDrawer();
            }}>
                Coaches
            </Nav.Item>
            <Nav.Item onClick={() => onCloseDrawer()}>
                Pricing
            </Nav.Item>
            <Nav.Menu title="Solutions" className="mobile-nav-item">
                <Nav.Item onClick={() => onCloseDrawer()}>AI Apps</Nav.Item>
                <Nav.Item onClick={() => onCloseDrawer()}>Composable Commerce</Nav.Item>
            </Nav.Menu>
            <Nav.Menu title="Colabora" className="mobile-nav-item">
                <Nav.Item onClick={() => {
                    navigate('/registrarse-coach');
                    onCloseDrawer();
                }}>
                    Registro de Coach
                </Nav.Item>
                <Nav.Item onClick={() => onCloseDrawer()}>Contact Us</Nav.Item>
            </Nav.Menu>
            <Nav.Item onClick={() => {
                navigate('/registrarse');
                onCloseDrawer();
            }}>
                Regístrate
            </Nav.Item>
            <Nav.Item onClick={() => {
                navigate('/login');
                onCloseDrawer();
            }}>
                Iniciar Sesión
            </Nav.Item>
        </Nav>
    </Drawer.Body>
</Drawer>);

const ClientDesktopNavbar = ({activeKey, setActiveKey, dispatch}) => {
    const navigate = useNavigate();

    return (<Navbar appearance="subtle" className="custom-navbar">
        <Navbar.Brand className="custom-navbar-brand" onClick={() => navigate('/')}>
            <div className="entrenalo-logo">
                <BicepsFlexed/>
                <span className="texto-entrenalo">ENTRENALO</span>
            </div>
        </Navbar.Brand>
        <Nav className="custom-nav" onSelect={setActiveKey} activeKey={activeKey}>
            <Nav.Item eventKey="1" onClick={() => navigate('/mi-progreso')}>Mi Progreso</Nav.Item>
            <Nav.Item eventKey="2" onClick={() => navigate('/agendar-sesion')}>Agendar Sesion</Nav.Item>
            <Nav.Item eventKey="3" onClick={() => navigate('/analisis')}>Análisis</Nav.Item>
        </Nav>
        <Nav className="custom-nav-right" pullRight>
            <Nav.Item onClick={() => navigate('/perfil')}>Perfil</Nav.Item>
            <Nav.Item onClick={() => {
                dispatch(logout());
                navigate('/');
            }}>Cerrar Sesión</Nav.Item>
        </Nav>
    </Navbar>);
};

const ClientMobileNavbar = ({onMenuClick}) => {
    const navigate = useNavigate();

    return (<Navbar appearance="subtle" className="mobile-navbar">
        <div className="mobile-navbar-content">
            <Navbar.Brand
                className="mobile-navbar-brand"
                onClick={() => navigate('/')}>
                <div className="entrenalo-logo">
                    <BicepsFlexed/>
                    <span className="texto-entrenalo">ENTRENALO</span>
                </div>
            </Navbar.Brand>
            <IconButton
                icon={<MenuIcon/>}
                className="menu-icon-button"
                onClick={onMenuClick}
            />
        </div>
    </Navbar>);
};

const ClientMobileDrawer = ({showDrawer, onCloseDrawer, dispatch}) => {
    const navigate = useNavigate();

    return (<Drawer
        size="xs"
        className="drawer"
        placement="right"
        open={showDrawer}
        onClose={onCloseDrawer}
    >
        <Drawer.Header>
            <Drawer.Title>Menú</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="drawer-body">
            <Nav vertical>
                <Nav.Item onClick={() => {
                    navigate('/mi-progreso');
                    onCloseDrawer();
                }}>
                    Mi Progreso
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/agendar-sesion');
                    onCloseDrawer();
                }}>
                    Agendar Sesion
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/analisis');
                    onCloseDrawer();
                }}>
                    Análisis
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/perfil');
                    onCloseDrawer();
                }}>
                    Perfil
                </Nav.Item>
                <Nav.Item onClick={() => {
                    dispatch(logout());
                    onCloseDrawer();
                    navigate('/');
                }}>
                    Cerrar Sesión
                </Nav.Item>
            </Nav>
        </Drawer.Body>
    </Drawer>);
};

const CoachDesktopNavbar = ({activeKey, setActiveKey, dispatch}) => {
    const navigate = useNavigate();
    return (<Navbar appearance="subtle" className="custom-navbar">
        <Navbar.Brand className="custom-navbar-brand" onClick={() => navigate('/')}>
            <div className="entrenalo-logo">
                <BicepsFlexed/>
                <span className="texto-entrenalo">ENTRENALO</span>
            </div>
        </Navbar.Brand>
        <Nav className="custom-nav" onSelect={setActiveKey} activeKey={activeKey}>
            <Nav.Item eventKey="1" onClick={() => navigate('/mis-clientes')}>Mis Clientes</Nav.Item>
            <Nav.Item eventKey="2" onClick={() => navigate('/agendar-sesion')}>Agendar Sesion</Nav.Item>
            <Nav.Item eventKey="3" onClick={() => navigate('/analisis')}>Análisis</Nav.Item>
        </Nav>
        <Nav className="custom-nav-right" pullRight>
            <Nav.Item onClick={() => navigate('/perfil')}>Perfil</Nav.Item>
            <Nav.Item onClick={() => {
                dispatch(logout());
                navigate('/');
            }}>Cerrar Sesión</Nav.Item>
        </Nav>
    </Navbar>);
};

const CoachMobileNavbar = ({onMenuClick}) => {
    const navigate = useNavigate();

    return (<Navbar appearance="subtle" className="mobile-navbar">
        <div className="mobile-navbar-content">
            <Navbar.Brand
                className="mobile-navbar-brand"
                onClick={() => navigate('/')}>
                <div className="entrenalo-logo">
                    <BicepsFlexed/>
                    <span className="texto-entrenalo">ENTRENALO</span>
                </div>
            </Navbar.Brand>
            <IconButton
                icon={<MenuIcon/>}
                className="menu-icon-button"
                onClick={onMenuClick}
            />
        </div>
    </Navbar>);
};

const CoachMobileDrawer = ({showDrawer, onCloseDrawer, dispatch}) => {
    const navigate = useNavigate();

    return (<Drawer
        size="xs"
        className="drawer"
        placement="right"
        open={showDrawer}
        onClose={onCloseDrawer}
    >
        <Drawer.Header>
            <Drawer.Title>Menú</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="drawer-body">
            <Nav vertical>
                <Nav.Item onClick={() => {
                    navigate('/mis-clientes');
                    onCloseDrawer();
                }}>
                    Mis Clientes
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/agendar-sesion');
                    onCloseDrawer();
                }}>
                    Agendar Sesion
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/analisis');
                    onCloseDrawer();
                }}>
                    Análisis
                </Nav.Item>
                <Nav.Item onClick={() => {
                    navigate('/perfil');
                    onCloseDrawer();
                }}>
                    Perfil
                </Nav.Item>
                <Nav.Item onClick={() => {
                    dispatch(logout());
                    onCloseDrawer();
                    navigate('/');
                }}>
                    Cerrar Sesión
                </Nav.Item>
            </Nav>
        </Drawer.Body>
    </Drawer>);
};

const CustomNavbar = ({userRole, isAuthenticated}) => {
    const [activeKey, setActiveKey] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderDesktopNavbar = () => {
        if (isAuthenticated) {
            if (userRole === 'client') {
                return <ClientDesktopNavbar activeKey={activeKey} setActiveKey={setActiveKey} dispatch={dispatch}/>;
            } else if (userRole === 'coach') {
                return <CoachDesktopNavbar activeKey={activeKey} setActiveKey={setActiveKey} dispatch={dispatch}/>;
            }
        } else {
            return <UnauthenticatedDesktopNavbar activeKey={activeKey} setActiveKey={setActiveKey}/>;
        }
    };

    const renderMobileNavbar = () => {
        if (isAuthenticated) {
            if (userRole === 'client') {
                return <ClientMobileNavbar onMenuClick={() => setShowDrawer(true)}/>;
            } else if (userRole === 'coach') {
                return <CoachMobileNavbar onMenuClick={() => setShowDrawer(true)}/>;
            }
        } else {
            return <UnauthenticatedMobileNavbar onMenuClick={() => setShowDrawer(true)}/>;
        }
    };

    const renderDrawer = () => {
        if (isAuthenticated) {
            if (userRole === 'client') {
                return (<ClientMobileDrawer
                    showDrawer={showDrawer}
                    onCloseDrawer={() => setShowDrawer(false)}
                    dispatch={dispatch}
                />);
            } else if (userRole === 'coach') {
                return (<CoachMobileDrawer
                    showDrawer={showDrawer}
                    onCloseDrawer={() => setShowDrawer(false)}
                    dispatch={dispatch}
                />);
            }
        } else {
            return (<UnauthenticatedMobileDrawer
                showDrawer={showDrawer}
                onCloseDrawer={() => setShowDrawer(false)}
                navigate={navigate}
            />);
        }
    };

    return (<>
        <Container>
            {isMobile ? (<>
                {renderMobileNavbar()}
                {renderDrawer()}
            </>) : (renderDesktopNavbar())}
        </Container>
        <style jsx>{`
            .mobile-navbar {
                background-color: #fff;
                border-bottom: 1px solid #eaeaea;
                padding: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1000;
            }

            .mobile-navbar-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
            }

            .mobile-navbar-brand {
                color: #000;
                font-weight: bold;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 0;
            }

            .entrenalo-logo {
                display: flex;
                align-items: center;
            }

            .texto-entrenalo {
                margin-left: 5px;
                font-size: 1.15rem;
            }

            .menu-icon-button {
                border: none;
                background: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 0;
            }

            .mobile-nav-item {
                margin-bottom: 15px;
                font-size: 1.1em;
                color: #333;
            }

            .custom-navbar {
                background-color: #fff;
                border-bottom: 1px solid #eaeaea;
                padding: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1000;
            }

            .custom-navbar-brand {
                color: #000;
                font-weight: bold;
                cursor: pointer;
            }

            .custom-nav > .rs-nav-item,
            .custom-nav > .rs-dropdown > .rs-dropdown-toggle {
                margin: 0 20px;
                color: #333;
                cursor: pointer;
            }

            .custom-nav-item-content,
            .custom-dropdown-item-content {
                transition: color 0.3s;
            }

            .custom-dropdown-item:hover {
                background-color: #f0f0f0;
            }
        `}</style>
    </>);
};

export default React.memo(CustomNavbar);
import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Input,
    Message,
    SelectPicker,
    TagPicker,
    Uploader,
    useToaster,
} from 'rsuite';
import {BookOpen, ChevronLeft, ChevronRight, Plus, Settings, Trash2, UserCircle,} from 'lucide-react';
import 'rsuite/dist/rsuite.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {clearRegisterState, registerCoach} from '../../store/coachRegisterSlice';

const Textarea = React.forwardRef((props, ref) => (<Input {...props} as="textarea" ref={ref}/>));

const specialtyOptions = [{label: 'Entrenamiento personal', value: 'personal-training'}, {
    label: 'Nutrición deportiva', value: 'nutrition'
}, {label: 'Yoga', value: 'yoga'}, {label: 'Pilates', value: 'pilates'}, {
    label: 'Crossfit', value: 'crossfit'
}, {label: 'Rehabilitación', value: 'rehabilitation'}, {
    label: 'Entrenamiento funcional', value: 'functional-training'
}, {label: 'Fuerza y acondicionamiento', value: 'strength-conditioning'},];

const languageOptions = [{label: 'Español', value: 'spanish'}, {label: 'Inglés', value: 'english'}, {
    label: 'Francés', value: 'french'
}, {label: 'Alemán', value: 'german'}, {label: 'Italiano', value: 'italian'}, {
    label: 'Portugués', value: 'portuguese'
},];

const sessionTypeOptions = [{label: 'Individual', value: 'individual'}, {
    label: 'Grupal', value: 'group'
}, {label: 'Online', value: 'online'}, {label: 'A domicilio', value: 'home'}, {
    label: 'Corporativo', value: 'corporate'
},];

const availabilityConfigurations = [{
    label: 'Estándar (9:00 - 17:00, 1h libre a las 13:00)', value: 'standard'
}, {
    label: 'Madrugador (8:00 - 16:00, 1h libre a las 12:00)', value: 'early'
}, {
    label: 'Vespertino (11:00 - 19:00, 1h libre a las 15:00)', value: 'late'
}, {label: 'Fin de semana (Sáb-Dom, 10:00 - 16:00)', value: 'weekend'}, {label: 'Personalizado', value: 'custom'},];

const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo',];
const timeSlots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',];

export default function App() {
    const dispatch = useDispatch();
    const toaster = useToaster();

    const {isRegistering, registerSuccess, registerError} = useSelector((state) => state.coachRegister);

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        birthdate: null,
        gender: '',
        location: '',
        specialties: [],
        experiences: [{title: '', company: '', period: ''}],
        certifications: [{name: '', issuer: '', year: ''}],
        education: [{degree: '', institution: '', year: ''}],
        languages: [],
        availability: {},
        availabilityConfig: 'custom',
        sessionTypes: [{type: '', rate: '', observations: ''}],
        bio: '',
        profilePicture: null,
        additionalServices: '',
        equipmentProvided: '',
        cancellationPolicy: '',
    });

    const [profilePictureFileList, setProfilePictureFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const handleInputChange = (value, name) => {
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleMultiSelectChange = (value, name) => {
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleFileChange = (fileList) => {
        setProfilePictureFileList(fileList);
        if (fileList && fileList.length > 0) {
            setFormData((prev) => ({
                ...prev, profilePicture: fileList[0].blobFile,
            }));
        } else {
            setFormData((prev) => ({...prev, profilePicture: null}));
        }
    };

    const handleArrayInputChange = (index, field, subfield, value) => {
        setFormData((prev) => {
            const newArray = [...prev[field]];
            newArray[index][subfield] = value;
            return {...prev, [field]: newArray};
        });
    };

    const addArrayItem = (field) => {
        setFormData((prev) => ({
            ...prev, [field]: field === 'experiences' ? [...prev[field], {
                title: '', company: '', period: ''
            }] : field === 'certifications' ? [...prev[field], {
                name: '', issuer: '', year: ''
            }] : [...prev[field], {degree: '', institution: '', year: ''}],
        }));
    };

    const removeArrayItem = (field, index) => {
        setFormData((prev) => ({
            ...prev, [field]: prev[field].filter((_, i) => i !== index),
        }));
    };

    const handleAvailabilityChange = (day, time) => {
        setFormData((prev) => ({
            ...prev, availabilityConfig: 'custom', availability: {
                ...prev.availability, [day]: {
                    ...(prev.availability[day] || {}), [time]: !(prev.availability[day]?.[time] || false),
                },
            },
        }));
    };

    const handleSessionTypeChange = (value, index, field) => {
        setFormData((prev) => {
            const newSessionTypes = [...prev.sessionTypes];
            newSessionTypes[index] = {
                ...newSessionTypes[index], [field]: value,
            };
            return {...prev, sessionTypes: newSessionTypes};
        });
    };

    const addSessionType = () => {
        setFormData((prev) => ({
            ...prev, sessionTypes: [...prev.sessionTypes, {type: '', rate: '', observations: ''},],
        }));
    };

    const removeSessionType = (index) => {
        setFormData((prev) => ({
            ...prev, sessionTypes: prev.sessionTypes.filter((_, i) => i !== index),
        }));
    };

    const handleAvailabilityConfigChange = (value) => {
        setFormData((prev) => ({...prev, availabilityConfig: value}));

        if (value !== 'custom') {
            let newAvailability = {};

            daysOfWeek.forEach((day) => {
                newAvailability[day] = {};
                timeSlots.forEach((time) => {
                    let isAvailable = false;
                    switch (value) {
                        case 'standard':
                            isAvailable = time >= '09:00' && time <= '17:00' && time !== '13:00';
                            break;
                        case 'early':
                            isAvailable = time >= '08:00' && time <= '16:00' && time !== '12:00';
                            break;
                        case 'late':
                            isAvailable = time >= '11:00' && time <= '19:00' && time !== '15:00';
                            break;
                        case 'weekend':
                            isAvailable = (day === 'Sábado' || day === 'Domingo') && time >= '10:00' && time <= '16:00';
                            break;
                        default:
                            isAvailable = false;
                    }
                    newAvailability[day][time] = isAvailable;
                });
            });

            setFormData((prev) => ({...prev, availability: newAvailability}));
        }
    };

    const handleNext = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePrev = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleSubmit = () => {
        const dataToSubmit = {
            ...formData, birthdate: formData.birthdate ? formData.birthdate.toISOString().split('T')[0] : null,
        };
        dispatch(registerCoach(dataToSubmit));
    };

    const handleClear = () => {
        dispatch(clearRegisterState());
        setFormData({
            name: '',
            email: '',
            phone: '',
            birthdate: null,
            gender: '',
            location: '',
            specialties: [],
            experiences: [{title: '', company: '', period: ''}],
            certifications: [{name: '', issuer: '', year: ''}],
            education: [{degree: '', institution: '', year: ''}],
            languages: [],
            availability: {},
            availabilityConfig: 'custom',
            sessionTypes: [{type: '', rate: '', observations: ''}],
            bio: '',
            profilePicture: null,
            additionalServices: '',
            equipmentProvided: '',
            cancellationPolicy: '',
        });
        setProfilePictureFileList([]);
    };

    useEffect(() => {
        if (registerSuccess) {
            toaster.push(<Message type="success" closable duration={5000}>
                Coach registrado exitosamente.
            </Message>, {placement: 'topEnd'});
            dispatch(clearRegisterState());
            handleClear();
            setStep(1);
        }

        if (registerError) {
            toaster.push(<Message type="error" closable duration={5000}>
                {registerError}
            </Message>, {placement: 'topEnd'});
            dispatch(clearRegisterState());
        }
    }, [registerSuccess, registerError, dispatch, toaster]);

    const renderStep1 = () => (<Form fluid>
        <div className="form-row">
            <div className="form-column">
                <Form.Group controlId="name-1">
                    <Form.ControlLabel>Nombre completo</Form.ControlLabel>
                    <Form.Control
                        name="name"
                        value={formData.name}
                        onChange={(value) => handleInputChange(value, 'name')}
                        placeholder="Ingresa tu nombre completo"
                        accepter={Input}
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
            <div className="form-column">
                <Form.Group controlId="email-1">
                    <Form.ControlLabel>Correo electrónico</Form.ControlLabel>
                    <Form.Control
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={(value) => handleInputChange(value, 'email')}
                        placeholder="ejemplo@correo.com"
                        accepter={Input}
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
        </div>

        <div className="form-row">
            <div className="form-column">
                <Form.Group controlId="phone-1">
                    <Form.ControlLabel>Teléfono</Form.ControlLabel>
                    <Form.Control
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={(value) => handleInputChange(value, 'phone')}
                        placeholder="123-456-7890"
                        accepter={Input}
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
            <div className="form-column">
                <Form.Group controlId="birthdate-1">
                    <Form.ControlLabel>Fecha de nacimiento</Form.ControlLabel>
                    <Form.Control
                        name="birthdate"
                        value={formData.birthdate}
                        onChange={(value) => handleInputChange(value, 'birthdate')}
                        placeholder="Selecciona una fecha"
                        accepter={DatePicker}
                        block
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
        </div>

        <div className="form-row">
            <div className="form-column">
                <Form.Group controlId="gender-1">
                    <Form.ControlLabel>Género</Form.ControlLabel>
                    <SelectPicker
                        data={[{label: 'Masculino', value: 'male'}, {
                            label: 'Femenino', value: 'female'
                        }, {label: 'Otro', value: 'other'}, {
                            label: 'Prefiero no decir', value: 'prefer-not-to-say'
                        },]}
                        value={formData.gender}
                        onChange={(value) => handleInputChange(value, 'gender')}
                        placeholder="Selecciona un género"
                        block
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
            <div className="form-column">
                <Form.Group controlId="location-1">
                    <Form.ControlLabel>Ubicación</Form.ControlLabel>
                    <Form.Control
                        name="location"
                        value={formData.location}
                        onChange={(value) => handleInputChange(value, 'location')}
                        placeholder="Ciudad, Estado"
                        accepter={Input}
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
        </div>
    </Form>);

    const renderStep2 = () => (<Form fluid>
        <Form.Group controlId="specialties-1">
            <Form.ControlLabel>Especialidades</Form.ControlLabel>
            <TagPicker
                data={specialtyOptions}
                value={formData.specialties}
                onChange={(value) => handleMultiSelectChange(value, 'specialties')}
                placeholder="Selecciona tus especialidades"
                block
                searchable={true}
                multiple={true}
                cleanable
                style={{width: '100%'}}
            />
            <Form.HelpText>Selecciona una o más especialidades</Form.HelpText>
        </Form.Group>

        <div className="section">
            <h3>Experiencia Laboral</h3>
            {formData.experiences.map((exp, index) => (<div key={index} className="subsection">
                <Form fluid>
                    <div className="form-row-3">
                        <div className="form-column-3">
                            <Form.Group controlId={`exp-title-${index}`}>
                                <Form.ControlLabel>Título</Form.ControlLabel>
                                <Form.Control
                                    name={`experiences[${index}].title`}
                                    value={exp.title}
                                    onChange={(value) => handleArrayInputChange(index, 'experiences', 'title', value)}
                                    placeholder="Título del puesto"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`exp-company-${index}`}>
                                <Form.ControlLabel>Empresa</Form.ControlLabel>
                                <Form.Control
                                    name={`experiences[${index}].company`}
                                    value={exp.company}
                                    onChange={(value) => handleArrayInputChange(index, 'experiences', 'company', value)}
                                    placeholder="Nombre de la empresa"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`exp-period-${index}`}>
                                <Form.ControlLabel>Periodo</Form.ControlLabel>
                                <Form.Control
                                    name={`experiences[${index}].period`}
                                    value={exp.period}
                                    onChange={(value) => handleArrayInputChange(index, 'experiences', 'period', value)}
                                    placeholder="Ej: 2018 - 2020"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {index > 0 && (<Button
                    appearance="subtle"
                    color="red"
                    onClick={() => removeArrayItem('experiences', index)}
                    icon={<Trash2 size={16}/>}
                >
                    Eliminar experiencia
                </Button>)}
            </div>))}
            <Button
                appearance="primary"
                onClick={() => addArrayItem('experiences')}
                icon={<Plus/>}
                style={{marginTop: '10px'}}
            >
                Agregar experiencia
            </Button>
        </div>

        <div className="section">
            <h3>Certificaciones</h3>
            {formData.certifications.map((cert, index) => (<div key={index} className="subsection">
                <Form fluid>
                    <div className="form-row-3">
                        <div className="form-column-3">
                            <Form.Group controlId={`cert-name-${index}`}>
                                <Form.ControlLabel>Nombre</Form.ControlLabel>
                                <Form.Control
                                    name={`certifications[${index}].name`}
                                    value={cert.name}
                                    onChange={(value) => handleArrayInputChange(index, 'certifications', 'name', value)}
                                    placeholder="Nombre de la certificación"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`cert-issuer-${index}`}>
                                <Form.ControlLabel>Emisor</Form.ControlLabel>
                                <Form.Control
                                    name={`certifications[${index}].issuer`}
                                    value={cert.issuer}
                                    onChange={(value) => handleArrayInputChange(index, 'certifications', 'issuer', value)}
                                    placeholder="Entidad emisora"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`cert-year-${index}`}>
                                <Form.ControlLabel>Año</Form.ControlLabel>
                                <Form.Control
                                    name={`certifications[${index}].year`}
                                    value={cert.year}
                                    onChange={(value) => handleArrayInputChange(index, 'certifications', 'year', value)}
                                    placeholder="Ej: 2021"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {index > 0 && (<Button
                    appearance="subtle"
                    color="red"
                    onClick={() => removeArrayItem('certifications', index)}
                    icon={<Trash2 size={16}/>}
                >
                    Eliminar certificación
                </Button>)}
            </div>))}
            <Button
                appearance="primary"
                onClick={() => addArrayItem('certifications')}
                icon={<Plus/>}
                style={{marginTop: '10px'}}
            >
                Agregar certificación
            </Button>
        </div>

        <div className="section">
            <h3>Educación y Formación</h3>
            {formData.education.map((edu, index) => (<div key={index} className="subsection">
                <Form fluid>
                    <div className="form-row-3">
                        <div className="form-column-3">
                            <Form.Group controlId={`edu-degree-${index}`}>
                                <Form.ControlLabel>Título</Form.ControlLabel>
                                <Form.Control
                                    name={`education[${index}].degree`}
                                    value={edu.degree}
                                    onChange={(value) => handleArrayInputChange(index, 'education', 'degree', value)}
                                    placeholder="Título obtenido"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`edu-institution-${index}`}>
                                <Form.ControlLabel>Institución</Form.ControlLabel>
                                <Form.Control
                                    name={`education[${index}].institution`}
                                    value={edu.institution}
                                    onChange={(value) => handleArrayInputChange(index, 'education', 'institution', value)}
                                    placeholder="Nombre de la institución"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`edu-year-${index}`}>
                                <Form.ControlLabel>Año</Form.ControlLabel>
                                <Form.Control
                                    name={`education[${index}].year`}
                                    value={edu.year}
                                    onChange={(value) => handleArrayInputChange(index, 'education', 'year', value)}
                                    placeholder="Ej: 2020"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {index > 0 && (<Button
                    appearance="subtle"
                    color="red"
                    onClick={() => removeArrayItem('education', index)}
                    icon={<Trash2 size={16}/>}
                >
                    Eliminar educación
                </Button>)}
            </div>))}
            <Button
                appearance="primary"
                onClick={() => addArrayItem('education')}
                icon={<Plus/>}
                style={{marginTop: '10px'}}
            >
                Agregar educación
            </Button>
        </div>

        <Form.Group controlId="languages-1">
            <Form.ControlLabel>Idiomas</Form.ControlLabel>
            <TagPicker
                data={languageOptions}
                value={formData.languages}
                onChange={(value) => handleMultiSelectChange(value, 'languages')}
                placeholder="Selecciona los idiomas que hablas"
                block
                searchable={true}
                cleanable
                style={{width: '100%'}}
                multiple
            />
            <Form.HelpText>Selecciona uno o más idiomas</Form.HelpText>
        </Form.Group>
    </Form>);

    const renderStep3 = () => (<Form fluid>
        <Form.Group controlId="availabilityConfig-1">
            <Form.ControlLabel>Configuración de disponibilidad</Form.ControlLabel>
            <SelectPicker
                data={availabilityConfigurations}
                value={formData.availabilityConfig}
                onChange={handleAvailabilityConfigChange}
                placeholder="Selecciona una configuración"
                block
                searchable={true}
                cleanable
                style={{width: '100%'}}
            />
            <Form.HelpText>
                Selecciona una configuración predefinida o personaliza tu disponibilidad
            </Form.HelpText>
        </Form.Group>

        <div className="availability-table">
            <table>
                <thead>
                <tr>
                    <th>Hora</th>
                    {daysOfWeek.map((day) => (<th key={day}>{day}</th>))}
                </tr>
                </thead>
                <tbody>
                {timeSlots.map((time) => (<tr key={time}>
                    <td>{time}</td>
                    {daysOfWeek.map((day) => (<td key={`${day}-${time}`}>
                        <Checkbox
                            checked={formData.availability[day]?.[time] || false}
                            onChange={() => handleAvailabilityChange(day, time)}
                        />
                    </td>))}
                </tr>))}
                </tbody>
            </table>
        </div>
        <p className="help-text">
            Haga clic en las casillas para seleccionar su disponibilidad. Esta selección se aplicará a todas las
            semanas.
        </p>

        <div className="section">
            <h3>Tipos de sesiones ofrecidas</h3>
            {formData.sessionTypes.map((session, index) => (<div key={index} className="subsection">
                <Form fluid>
                    <div className="form-row-3">
                        <div className="form-column-3">
                            <Form.Group controlId={`session-type-${index}`}>
                                <Form.ControlLabel>Tipo de sesión</Form.ControlLabel>
                                <SelectPicker
                                    data={sessionTypeOptions}
                                    value={session.type}
                                    onChange={(value) => handleSessionTypeChange(value, index, 'type')}
                                    placeholder="Selecciona un tipo de sesión"
                                    block
                                    searchable={false}
                                    cleanable
                                    style={{width: '100%'}}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`session-rate-${index}`}>
                                <Form.ControlLabel>Tarifa</Form.ControlLabel>
                                <Form.Control
                                    name={`sessionTypes[${index}].rate`}
                                    value={session.rate}
                                    onChange={(value) => handleSessionTypeChange(value, index, 'rate')}
                                    placeholder="Ej: $50/hora"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                        <div className="form-column-3">
                            <Form.Group controlId={`session-observations-${index}`}>
                                <Form.ControlLabel>Observaciones</Form.ControlLabel>
                                <Form.Control
                                    name={`sessionTypes[${index}].observations`}
                                    value={session.observations}
                                    onChange={(value) => handleSessionTypeChange(value, index, 'observations')}
                                    placeholder="Observaciones adicionales"
                                    accepter={Input}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {index > 0 && (<Button
                    appearance="subtle"
                    color="red"
                    onClick={() => removeSessionType(index)}
                    icon={<Trash2 size={16}/>}
                >
                    Eliminar tipo de sesión
                </Button>)}
            </div>))}
            <Button
                appearance="primary"
                onClick={addSessionType}
                icon={<Plus/>}
                style={{marginTop: '10px'}}
            >
                Agregar tipo de sesión
            </Button>
        </div>

        <div className="form-row">
            <div className="form-column">
                <Form.Group controlId="additionalServices-1">
                    <Form.ControlLabel>Servicios adicionales</Form.ControlLabel>
                    <Form.Control
                        name="additionalServices"
                        value={formData.additionalServices}
                        onChange={(value) => handleInputChange(value, 'additionalServices')}
                        accepter={Textarea}
                        placeholder="Describe cualquier servicio adicional que ofrezcas"
                        rows={3}
                    />
                    <Form.HelpText>Opcional</Form.HelpText>
                </Form.Group>
            </div>
            <div className="form-column">
                <Form.Group controlId="equipmentProvided-1">
                    <Form.ControlLabel>Equipo proporcionado</Form.ControlLabel>
                    <Form.Control
                        name="equipmentProvided"
                        value={formData.equipmentProvided}
                        onChange={(value) => handleInputChange(value, 'equipmentProvided')}
                        accepter={Textarea}
                        placeholder="Lista el equipo que proporcionas para las sesiones"
                        rows={3}
                    />
                    <Form.HelpText>Opcional</Form.HelpText>
                </Form.Group>
            </div>
        </div>

        <div className="form-row">
            <div className="form-column">
                <Form.Group controlId="cancellationPolicy-1">
                    <Form.ControlLabel>Política de cancelación</Form.ControlLabel>
                    <Form.Control
                        name="cancellationPolicy"
                        value={formData.cancellationPolicy}
                        onChange={(value) => handleInputChange(value, 'cancellationPolicy')}
                        accepter={Textarea}
                        placeholder="Describe tu política de cancelación"
                        rows={3}
                    />
                    <Form.HelpText>Opcional</Form.HelpText>
                </Form.Group>
            </div>
            <div className="form-column">
                <Form.Group controlId="bio-1">
                    <Form.ControlLabel>Biografía profesional</Form.ControlLabel>
                    <Form.Control
                        name="bio"
                        value={formData.bio}
                        onChange={(value) => handleInputChange(value, 'bio')}
                        accepter={Textarea}
                        placeholder="Escribe una breve biografía profesional"
                        rows={4}
                        required
                    />
                    <Form.HelpText>Requerido</Form.HelpText>
                </Form.Group>
            </div>
        </div>

        <Form.Group controlId="profilePicture-1">
            <Form.ControlLabel>Foto de perfil</Form.ControlLabel>
            <Uploader
                listType="picture-text"
                fileList={profilePictureFileList}
                onChange={handleFileChange}
                action="//jsonplaceholder.typicode.com/posts/"
                accept="image/*"
                maxFileCount={1}
                autoUpload={false}
                disableMultipart={false}
                style={{width: '100%'}}
                renderFileInfo={(file, fileElement) => {
                    return (<>
                        <span>Nombre del archivo: {file.name}</span>
                    </>);
                }}
            >
                <Button appearance="primary" disabled={uploading}>
                    Seleccionar imagen
                </Button>
            </Uploader>
            <Form.HelpText>Opcional</Form.HelpText>
        </Form.Group>
    </Form>);

    return (<div className="form-container">
        <h2>Registro de Coach</h2>
        <div className="progress-container">
            <div className="step-indicators">
                {[{num: 1, icon: UserCircle, label: 'Personal'}, {
                    num: 2, icon: BookOpen, label: 'Profesional'
                }, {num: 3, icon: Settings, label: 'Servicios'},].map(({num, icon: Icon, label}) => (<div
                    key={num}
                    className={`step-indicator ${step >= num ? 'active' : ''}`}
                >
                    <div className="step-icon">
                        <Icon/>
                    </div>
                    <span className="step-label">{label}</span>
                </div>))}
            </div>
            <div className="progress-bar">
                <div
                    className="progress-fill"
                    style={{width: `${((step - 1) / 2) * 100}%`}}
                ></div>
            </div>
        </div>
        <Form onSubmit={handleSubmit}>
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}

            <div className="button-container">
                {step > 1 && (<Button
                    appearance="default"
                    onClick={handlePrev}
                    icon={<ChevronLeft/>}
                >
                    Anterior
                </Button>)}
                {step < 3 ? (<Button
                    appearance="primary"
                    onClick={handleNext}
                    icon={<ChevronRight/>}
                >
                    Siguiente
                </Button>) : (<Button
                    appearance="success"
                    type="submit"
                    disabled={isRegistering}
                >
                    {isRegistering ? 'Registrando...' : 'Registrar Coach'}
                </Button>)}
            </div>
        </Form>
        <style jsx>{`
            .form-container {
                padding: 20px;
                max-width: 800px;
                margin: 6rem auto 3rem auto;
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            h2 {
                font-size: 24px;
                font-weight: 300;
                text-align: center;
                margin-bottom: 20px;
                color: #333;
            }

            .progress-container {
                margin-bottom: 30px;
            }

            .step-indicators {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }

            .step-indicator {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .step-icon {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: #e2e8f0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s ease;
            }

            .step-indicator.active .step-icon {
                background-color: #4a5568;
                color: white;
            }

            .step-label {
                font-size: 12px;
                margin-top: 8px;
                color: #718096;
            }

            .step-indicator.active .step-label {
                color: #4a5568;
                font-weight: 500;
            }

            .progress-bar {
                height: 4px;
                background-color: #e2e8f0;
                border-radius: 2px;
                overflow: hidden;
            }

            .progress-fill {
                height: 100%;
                background-color: #4a5568;
                transition: width 0.3s ease;
            }

            .section {
                background-color: #f7fafc;
                padding: 20px;
                border-radius: 8px;
                margin-bottom: 20px;
            }

            .subsection {
                background-color: #ffffff;
                padding: 15px;
                border-radius: 4px;
                margin-bottom: 15px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }

            h3 {
                font-size: 18px;
                margin-bottom: 15px;
                color: #2d3748;
            }

            .availability-table {
                overflow-x: auto;
                margin-bottom: 20px;
            }

            table {
                width: 100%;
                border-collapse: collapse;
            }

            th,
            td {
                border: 1px solid #e2e8f0;
                padding: 10px;
                text-align: center;
            }

            .help-text {
                font-size: 12px;
                color: #718096;
                margin-top: 5px;
            }

            .button-container {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
            }

            .rs-btn {
                display: flex;
                align-items: center;
            }

            .rs-btn .icon {
                margin-right: 5px;
            }

            .form-row {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 20px;
            }

            .form-column {
                flex: 1;
                min-width: 250px;
            }

            .form-row-3 {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 20px;
            }

            .form-column-3 {
                flex: 1;
                min-width: 200px;
            }

            @media (max-width: 600px) {
                .step-indicators {
                    flex-direction: column;
                    align-items: center;
                }

                .button-container {
                    flex-direction: column;
                    gap: 10px;
                }

                .form-row,
                .form-row-3 {
                    flex-direction: column;
                }

                .form-column,
                .form-column-3 {
                    min-width: 100%;
                }
            }
        `}</style>
    </div>);
}

import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBed,
    faCalendarAlt,
    faComment,
    faDumbbell,
    faHeartbeat,
    faMedal,
    faRunning,
    faTrophy,
    faWeight
} from '@fortawesome/free-solid-svg-icons';
import {Navbar} from "../../layouts";
import {useSelector} from "react-redux";

const TimeRangeSelector = ({onRangeChange}) => (<div className="time-range-selector">
    <select onChange={(e) => onRangeChange(e.target.value)}>
        <option value="week">Esta Semana</option>
        <option value="month">Este Mes</option>
        <option value="year">Este Año</option>
    </select>
</div>);

const ProgressCard = ({icon, title, value, change}) => (<div className="progress-card">
    <div className="progress-card-header">
        <FontAwesomeIcon icon={icon} className="icon"/>
        <h3>{title}</h3>
    </div>
    <div className="progress-card-value">{value}</div>
    <div className={`progress-card-change ${change >= 0 ? 'positive' : 'negative'}`}>
        {change >= 0 ? '+' : ''}{change}% vs periodo anterior
    </div>
</div>);

const TimelineChart = ({data}) => (<div className="timeline-chart">
    <h3>Progreso en el Tiempo</h3>
    <div className="chart">
        {data.map((item, index) => (<div key={index} className="chart-column">
            <div className="chart-bar" style={{height: `${item.value}%`}}></div>
            <span className="chart-label">{item.label}</span>
        </div>))}
    </div>
</div>);

const GoalTracker = ({goals}) => (<div className="goal-tracker">
    <h3>Seguimiento de Objetivos</h3>
    {goals.map((goal, index) => (<div key={index} className="goal">
        <div className="goal-header">
            <span>{goal.name}</span>
            <span>{goal.progress}%</span>
        </div>
        <div className="goal-progress-bar">
            <div className="goal-progress" style={{width: `${goal.progress}%`}}></div>
        </div>
    </div>))}
</div>);

export default function TimedProgressDashboard() {

    const {isAuthenticated, userRole} = useSelector((state) => state.auth);


    const [timeRange, setTimeRange] = useState('week');

    // Datos de ejemplo que cambiarían según el rango de tiempo seleccionado
    const progressData = {
        week: {
            workouts: {value: '5', change: 20},
            calories: {value: '3,500', change: 5},
            distance: {value: '25 km', change: 10},
            timelineData: [{label: 'L', value: 60}, {label: 'M', value: 80}, {label: 'X', value: 40}, {
                label: 'J', value: 70
            }, {label: 'V', value: 90}, {label: 'S', value: 50}, {label: 'D', value: 30},],
            goals: [{name: 'Correr 5km', progress: 80}, {
                name: 'Levantar 100kg', progress: 60
            }, {name: 'Meditar 10min/día', progress: 40},]
        }, month: {
            workouts: {value: '22', change: 15},
            calories: {value: '15,000', change: 8},
            distance: {value: '110 km', change: 12},
            timelineData: [{label: 'S1', value: 70}, {label: 'S2', value: 85}, {label: 'S3', value: 60}, {
                label: 'S4', value: 90
            },],
            goals: [{name: 'Correr 5km', progress: 90}, {
                name: 'Levantar 100kg', progress: 75
            }, {name: 'Meditar 10min/día', progress: 60},]
        }, year: {
            workouts: {value: '250', change: 30},
            calories: {value: '180,000', change: 20},
            distance: {value: '1,300 km', change: 25},
            timelineData: [{label: 'Ene', value: 50}, {label: 'Feb', value: 60}, {
                label: 'Mar', value: 70
            }, {label: 'Abr', value: 65}, {label: 'May', value: 80}, {label: 'Jun', value: 85},],
            goals: [{name: 'Correr 5km', progress: 100}, {
                name: 'Levantar 100kg', progress: 90
            }, {name: 'Meditar 10min/día', progress: 80},]
        }
    };

    return (<>
        <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
        <div className="dashboard">
            <div className="dashboard-content">
                <h1>Tu Progreso en el Tiempo</h1>

                <TimeRangeSelector onRangeChange={setTimeRange}/>

                <div className="progress-cards">
                    <ProgressCard
                        icon={faDumbbell}
                        title="Entrenamientos"
                        value={progressData[timeRange].workouts.value}
                        change={progressData[timeRange].workouts.change}
                    />
                    <ProgressCard
                        icon={faHeartbeat}
                        title="Calorías Quemadas"
                        value={progressData[timeRange].calories.value}
                        change={progressData[timeRange].calories.change}
                    />
                    <ProgressCard
                        icon={faRunning}
                        title="Distancia Recorrida"
                        value={progressData[timeRange].distance.value}
                        change={progressData[timeRange].distance.change}
                    />
                </div>

                <div className="charts">
                    <TimelineChart data={progressData[timeRange].timelineData}/>
                    <GoalTracker goals={progressData[timeRange].goals}/>
                </div>

                <div className="stats">
                    <div className="stat-card">
                        <FontAwesomeIcon icon={faTrophy} className="icon" style={{color: '#fbbf24'}}/>
                        <div className="stat-card-content">
                            <div className="stat-card-label">Mejor Marca Personal</div>
                            <div className="stat-card-value">5km en 22:30</div>
                        </div>
                    </div>
                    <div className="stat-card">
                        <FontAwesomeIcon icon={faWeight} className="icon" style={{color: '#3b82f6'}}/>
                        <div className="stat-card-content">
                            <div className="stat-card-label">Cambio de Peso</div>
                            <div className="stat-card-value">-2.5 kg</div>
                        </div>
                    </div>
                    <div className="stat-card">
                        <FontAwesomeIcon icon={faBed} className="icon" style={{color: '#8b5cf6'}}/>
                        <div className="stat-card-content">
                            <div className="stat-card-label">Promedio de Sueño</div>
                            <div className="stat-card-value">7.5 horas</div>
                        </div>
                    </div>
                    <div className="stat-card">
                        <FontAwesomeIcon icon={faMedal} className="icon" style={{color: '#10b981'}}/>
                        <div className="stat-card-content">
                            <div className="stat-card-label">Logros Desbloqueados</div>
                            <div className="stat-card-value">5 nuevos</div>
                        </div>
                    </div>
                </div>

                <div className="upcoming-workouts">
                    <h2>Próximos Entrenamientos</h2>
                    {[{day: 'Mañana', time: '07:00', type: 'Cardio'}, {
                        day: 'Jueves', time: '18:30', type: 'Fuerza'
                    }, {day: 'Sábado', time: '10:00', type: 'Yoga'},].map((session, index) => (
                        <div key={index} className="workout-item">
                            <div className="workout-info">
                                <FontAwesomeIcon icon={faCalendarAlt} className="icon"/>
                                <div>
                                    <div className="workout-day">{session.day}</div>
                                    <div className="workout-time">{session.time}</div>
                                </div>
                            </div>
                            <div className="workout-type">{session.type}</div>
                        </div>))}
                </div>

                <div className="coach-feedback">
                    <h2>Feedback del Entrenador</h2>
                    {[{
                        name: 'Carlos Pérez', comment: '¡Gran progreso en tu resistencia! Sigue así.'
                    }, {
                        name: 'Ana Gómez', comment: 'Recuerda estirar después de cada sesión de fuerza.'
                    },].map((feedback, index) => (<div key={index} className="feedback-item">
                        <div className="feedback-info">
                            <FontAwesomeIcon icon={faComment} className="icon"/>
                            <div>
                                <div className="feedback-name">{feedback.name}</div>
                                <div className="feedback-comment">{feedback.comment}</div>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>
        </div>
        <style jsx>
            {`
                .dashboard {
                    margin-top: 3rem;
                    background-color: #f3f4f6;
                    min-height: 100vh;
                    padding: 2rem;
                    font-family: Arial, sans-serif;
                }

                .dashboard-content {
                    max-width: 1200px;
                    margin: 0 auto;
                }

                h1 {
                    font-size: 2rem;
                    font-weight: bold;
                    color: #1f2937;
                    margin-bottom: 2rem;
                }

                .time-range-selector {
                    margin-bottom: 1.5rem;
                }

                .time-range-selector select {
                    background-color: white;
                    border: 1px solid #d1d5db;
                    color: #374151;
                    padding: 0.5rem 1rem;
                    border-radius: 9999px;
                    font-size: 0.875rem;
                }

                .progress-cards {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                    gap: 1.5rem;
                    margin-bottom: 2rem;
                }

                .progress-card {
                    background-color: white;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                }

                .progress-card-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                }

                .progress-card-header .icon {
                    color: #6b7280;
                    margin-right: 0.5rem;
                }

                .progress-card-header h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    color: #374151;
                }

                .progress-card-value {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #111827;
                }

                .progress-card-change {
                    font-size: 0.875rem;
                }

                .progress-card-change.positive {
                    color: #10b981;
                }

                .progress-card-change.negative {
                    color: #ef4444;
                }

                .charts {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                    gap: 1.5rem;
                    margin-bottom: 2rem;
                }

                .timeline-chart, .goal-tracker {
                    background-color: white;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                }

                .timeline-chart h3, .goal-tracker h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: #374151;
                    margin-bottom: 1rem;
                }

                .chart {
                    display: flex;
                    align-items: flex-end;
                    height: 200px;
                    gap: 0.5rem;
                }

                .chart-column {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .chart-bar {
                    width: 2rem;
                    background-color: #6b7280;
                    border-radius: 0.25rem 0.25rem 0 0;
                }

                .chart-label {
                    margin-top: 0.5rem;
                    font-size: 0.75rem;
                    color: #6b7280;
                }

                .goal {
                    margin-bottom: 1rem;
                }

                .goal-header {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.875rem;
                    color: #374151;
                    margin-bottom: 0.25rem;
                }

                .goal-progress-bar {
                    background-color: #e5e7eb;
                    height: 0.5rem;
                    border-radius: 9999px;
                    overflow: hidden;
                }

                .goal-progress {
                    height: 100%;
                    background-color: #6b7280;
                }

                .stats {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    gap: 1rem;
                    margin-bottom: 2rem;
                }

                .stat-card {
                    background-color: white;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                    display: flex;
                    align-items: center;
                }

                .stat-card .icon {
                    font-size: 1.5rem;
                    margin-right: 1rem;
                }

                .stat-card-content {
                    flex: 1;
                }

                .stat-card-label {
                    font-size: 0.75rem;
                    color: #6b7280;
                }

                .stat-card-value {
                    font-size: 1.25rem;
                    font-weight: bold;
                    color: #111827;
                }

                .upcoming-workouts, .coach-feedback {
                    background-color: white;
                    padding: 1.5rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                    margin-bottom: 2rem;
                }

                .upcoming-workouts h2, .coach-feedback h2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #1f2937;
                    margin-bottom: 1rem;
                }

                .workout-item, .feedback-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1rem;
                }

                .workout-info, .feedback-info {
                    display: flex;
                    align-items: center;
                }

                .workout-info .icon, .feedback-info .icon {
                    color: #6b7280;
                    margin-right: 0.75rem;
                }

                .workout-day, .feedback-name {
                    font-weight: 600;
                }

                .workout-time, .feedback-comment {
                    font-size: 0.875rem;
                    color: #6b7280;
                }

                .workout-type {
                    background-color: #e5e7eb;
                    padding: 0.25rem 0.75rem;
                    border-radius: 9999px;
                    font-size: 0.75rem;
                }
            `}
        </style>
    </>);
}
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
    EncuentraTuCoach,
    Home,
    Login,
    Logout,
    Registrarse,
    RegistrarseCoach,
    TermsOfService,
    UserAgendarSesion,
    UserAnalisisIA,
    UserMiProgreso,
    UserPerfil
} from './pages';
import {useDispatch} from "react-redux";
import {loadAuthFromStorage} from "./store/authSlice";


function App() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(loadAuthFromStorage());
    }, [dispatch]);


    return (<div>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/registrarse" element={<Registrarse/>}/>
            <Route path="/encuentra-tu-coach" element={<EncuentraTuCoach/>}/>
            <Route path="/terms-of-service" element={<TermsOfService/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/registrarse-coach" element={<RegistrarseCoach/>}/>
            <Route path="/mi-progreso" element={<UserMiProgreso/>}/>
            <Route path="/perfil" element={<UserPerfil/>}/>
            <Route path="/analisis" element={<UserAnalisisIA/>}/>
            <Route path="/agendar-sesion" element={<UserAgendarSesion/>}/>

        </Routes>
    </div>);
}

export default App;

import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faFacebookF, faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faEye, faEyeSlash, faLock} from '@fortawesome/free-solid-svg-icons';
import {LoginSocialGoogle} from "reactjs-social-login";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {Navbar} from "../../layouts";
import {login} from '../../store/authSlice';
import {Message, useToaster} from 'rsuite';


export default function LoginForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState(null);
    const [credentials, setCredentials] = useState({
        email: '', password: '',
    });
    const navigate = useNavigate();
    const toaster = useToaster();

    const {isLoading, error, isAuthenticated, userRole} = useSelector((state) => state.auth);

    const dispatch = useDispatch();


    const showSuccess = useCallback((message) => {
        const notification = (<Message type="success" closable>
            {message}
        </Message>);
        toaster.push(notification, {placement: 'topEnd', duration: 5000});
    }, [toaster]);

    const showError = useCallback((message) => {
        const notification = (<Message
            type="error" closable>
            <strong>Error!</strong> {message}
        </Message>);
        toaster.push(notification, {placement: 'topEnd', duration: 5000});
    }, [toaster]);


    useEffect(() => {
        if (isAuthenticated) {
            showSuccess('¡Has iniciado sesión exitosamente!');
            navigate('/');
        }
    }, [isAuthenticated, navigate, showSuccess]);

    useEffect(() => {
        if (error) {
            showError(error);
        }
    }, [error, showError]);

    const onLoginStart = useCallback(() => {
        console.log('Proceso de inicio de sesión iniciado');
    }, []);

    const handleChange = (e) => {
        setCredentials({
            ...credentials, [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(credentials));
    };


    return (<div>
        <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
        <div className="login-container">
            <div className="login-form">
                <h2>¡Bienvenido de Nuevo!</h2>
                <p className="subtitle">Nos alegra verte nuevamente. Ingresa tus datos para continuar.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">
                            <FontAwesomeIcon icon={faEnvelope} className="icon"/> Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="tu@correo.com"
                            value={credentials.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">
                            <FontAwesomeIcon icon={faLock} className="icon"/> Contraseña
                        </label>
                        <div className="password-input">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                placeholder="Tu contraseña secreta"
                                value={credentials.password}
                                onChange={handleChange}
                                required
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                    </div>
                    <div className="form-options">
                        <label className="remember-me">
                            <input
                                type="checkbox"
                                name="rememberMe"
                                checked={credentials.rememberMe || false}
                                onChange={(e) => setCredentials({...credentials, rememberMe: e.target.checked})}
                            /> Recordarme
                        </label>
                        <a href="#" className="forgot-password">¿Olvidaste tu contraseña?</a>
                    </div>
                    <button type="submit" className="submit-btn" disabled={isLoading}>
                        {isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
                    </button>
                </form>
                <div className="social-login">
                    <p>O continúa con</p>
                    <div className="social-buttons">
                        <button className="social-btn facebook" aria-label="Iniciar sesión con Facebook">
                            <FontAwesomeIcon icon={faFacebookF}/>
                        </button>

                        <LoginSocialGoogle
                            isOnlyGetToken
                            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || "11"}
                            onLoginStart={onLoginStart}
                            onResolve={({provider, data}) => {
                                setProvider(provider);
                                setProfile(data);
                                showSuccess('Inicio de sesión con Google exitoso.');
                                navigate('/');
                            }}
                            onReject={(err) => {
                                console.log(err);
                                showError('Error al iniciar sesión con Google.');
                            }}
                        >
                            <button className="social-btn google" aria-label="Iniciar sesión con Google">
                                <FontAwesomeIcon icon={faGoogle}/>
                            </button>
                        </LoginSocialGoogle>

                        <button className="social-btn apple" aria-label="Iniciar sesión con Apple">
                            <FontAwesomeIcon icon={faApple}/>
                        </button>
                    </div>
                </div>
                <p className="signup-prompt">¿No tienes una cuenta?
                    <a onClick={() => {
                        navigate('/register')
                    }}> Regístrate ahora </a>
                </p>
            </div>
        </div>
        <style jsx>{`
            .login-container {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                background-color: #f7f7f7;
                font-family: 'Arial', sans-serif;
            }

            .login-form {
                background-color: white;
                padding: 2.5rem;
                border-radius: 12px;
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
                width: 100%;
                max-width: 400px;
            }

            h2 {
                color: #333;
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
                text-align: center;
            }

            .subtitle {
                color: #666;
                text-align: center;
                margin-bottom: 1.5rem;
            }

            .form-group {
                margin-bottom: 1.2rem;
            }

            label {
                display: block;
                margin-bottom: 0.5rem;
                color: #555;
                font-weight: 600;
            }

            .icon {
                margin-right: 8px;
            }

            input {
                width: 100%;
                padding: 0.75rem;
                border: 1px solid #ddd;
                border-radius: 6px;
                font-size: 1rem;
                transition: border-color 0.3s ease;
            }

            input:focus {
                border-color: #007bff;
                outline: none;
            }

            .password-input {
                position: relative;
            }

            .toggle-password {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                color: #666;
                cursor: pointer;
            }

            .form-options {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;
                font-size: 0.9rem;
            }

            .remember-me {
                display: flex;
                align-items: center;
            }

            .remember-me input {
                margin-right: 0.5rem;
            }

            .forgot-password {
                color: #007bff;
                text-decoration: none;
                cursor: pointer;
            }

            .forgot-password:hover {
                text-decoration: underline;
            }

            .submit-btn {
                width: 100%;
                padding: 0.75rem;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 6px;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }

            .submit-btn:hover {
                background-color: #0056b3;
            }

            .social-login {
                margin-top: 2rem;
                text-align: center;
            }

            .social-login p {
                color: #666;
                margin-bottom: 1rem;
            }

            .social-buttons {
                display: flex;
                justify-content: center;
                gap: 1rem;
            }

            .social-btn {
                width: 40px;
                height: 40px;
                border: none;
                border-radius: 50%;
                background-color: #f0f0f0;
                color: #333;
                font-size: 1.2rem;
                cursor: pointer;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .social-btn:hover {
                transform: translateY(-3px);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }

            .social-btn.facebook:hover {
                background-color: #1877f2;
                color: white;
            }

            .social-btn.google:hover {
                background-color: #db4437;
                color: white;
            }

            .social-btn.apple:hover {
                background-color: #000000;
                color: white;
            }

            .signup-prompt {
                margin-top: 1.5rem;
                text-align: center;
                font-size: 0.9rem;
                color: #666;
            }

            .signup-prompt a {
                color: #007bff;
                text-decoration: none;
                font-weight: 600;
                cursor: pointer;
            }

            .signup-prompt a:hover {
                text-decoration: underline;
            }
        `}</style>
    </div>);
}

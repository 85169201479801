import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const API_URL = 'http://localhost:8080';

const initialState = {
    userId: null,
    isAuthenticated: false,
    userRole: null,
    userName: null, userEmail: null, accessToken: null, refreshToken: null, isLoading: false, error: null,
};

export const login = createAsyncThunk('auth/login', async (credentials, {rejectWithValue}) => {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify(credentials),
        });

        const data = await response.json();

        if (!response.ok) {
            return rejectWithValue(data.message || 'Login failed');
        }

        return data;

    } catch (error) {
        return rejectWithValue(error.message || 'Login failed');
    }
});

export const refreshAccessToken = createAsyncThunk('auth/refreshAccessToken', async (refreshToken, {rejectWithValue}) => {
    try {
        const response = await fetch(`${API_URL}/access-token/refresh`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({refreshToken}),
        });

        const data = await response.json();

        if (!response.ok) {
            return rejectWithValue(data.message || 'Failed to refresh access token');
        }

        return data;
    } catch (error) {
        return rejectWithValue(error.message || 'Failed to refresh access token');
    }
});

const authSlice = createSlice({
    name: 'auth', initialState, reducers: {
        clearError(state) {
            state.error = null;
        }, logout(state) {
            state.userName = null;
            state.userEmail = null;
            state.userId = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.isLoading = false;
            state.userRole = null;
            state.error = null;
            localStorage.removeItem('auth');
        }, loadAuthFromStorage(state) {
            const userJSON = localStorage.getItem('auth');
            if (userJSON) {
                const userObject = JSON.parse(userJSON);
                state.userName = userObject.userName;
                state.userEmail = userObject.userEmail;
                state.userId = userObject.userId;
                state.accessToken = userObject.accessToken;
                state.refreshToken = userObject.refreshToken;
                state.isAuthenticated = true;
                state.userRole = userObject.userRole;
            }
        }
    }, extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userId = action.payload.userId;
                state.userName = action.payload.userName;
                state.userEmail = action.payload.userEmail;
                state.accessToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.isAuthenticated = true;
                state.userRole = 'client';


                localStorage.setItem('auth', JSON.stringify({
                    userEmail: action.payload.userEmail,
                    userId: action.payload.userId,
                    userName: action.payload.userName,
                    refreshToken: action.payload.refreshToken,
                    accessToken: action.payload.accessToken,
                    userRole: 'client',
                }));
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.isAuthenticated = false;
                state.userRole = null;
            })
            .addCase(refreshAccessToken.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.isLoading = false;
                state.accessToken = action.payload.accessToken;
                state.userEmail = action.payload.userEmail;
                state.userId = action.payload.userId;
                state.userName = action.payload.userName;
                state.isAuthenticated = true;

                const existingAuth = JSON.parse(localStorage.getItem('auth')) || {};
                localStorage.setItem('auth', JSON.stringify({
                    ...existingAuth,
                    accessToken: action.payload.accessToken,
                    userEmail: action.payload.userEmail,
                    userId: action.payload.userId,
                    userName: action.payload.userName,
                }));
            })
            .addCase(refreshAccessToken.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.userId = null;
                state.userName = null;
                state.userEmail = null;
                state.accessToken = null;
                state.refreshToken = null;
                state.isAuthenticated = false;
                state.userRole = null;
                localStorage.removeItem('auth');
            });
    },
});

export const {clearError, logout, loadAuthFromStorage} = authSlice.actions;

export default authSlice.reducer;

import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBullseye,
    faCalendarAlt,
    faCheck,
    faClipboard,
    faClock,
    faCog,
    faDumbbell,
    faHeart,
    faPencilAlt,
    faUser,
    faUserTie
} from '@fortawesome/free-solid-svg-icons'
import {Navbar} from "../../layouts";
import {useSelector} from "react-redux";

export default function Perfil() {
    const [editingSection, setEditingSection] = useState('')

    const {isAuthenticated, userRole} = useSelector((state) => state.auth);



    const sections = [{
        title: 'Información Personal', content: {
            nombre: 'Juan Pérez',
            edad: '30 años',
            genero: 'Masculino',
            email: 'juan@example.com',
            telefono: '+1 234 567 8900'
        }, icon: faUser
    }, {
        title: 'Información de Salud y Física', content: {
            peso: '75 kg',
            altura: '180 cm',
            imc: '23.1',
            cintura: '82 cm',
            pecho: '100 cm',
            piernas: '60 cm',
            brazos: '35 cm',
            historialMedico: 'Asma leve',
            lesionesRecientes: 'Ninguna',
            medicamentos: 'Inhalador (según sea necesario)'
        }, icon: faHeart
    }, {
        title: 'Objetivos Personales', content: {
            metaActual: 'Ganancia muscular',
            plazo: '6 meses',
            logros: ['Pérdida de 5 kg en 2 meses', 'Completó programa de 12 semanas de fuerza']
        }, icon: faBullseye
    }, {
        title: 'Planes Personalizados', content: {
            planEntrenamiento: 'Entrenamiento de fuerza 4 días/semana',
            planNutricion: 'Dieta alta en proteínas, 2500 kcal/día',
            notasEntrenador: 'Juan está progresando bien. Aumentar peso en press de banca la próxima semana.'
        }, icon: faClipboard
    }, {
        title: 'Configuración y Preferencias', content: {
            deportesFavoritos: ['Levantamiento de pesas', 'Natación'], horarioPreferido: 'Tardes (18:00 - 20:00)'
        }, icon: faCog
    }, {
        title: 'Próxima Sesión y Eventos', content: {
            proximaSesion: {
                fecha: '15/10/2023', hora: '18:30', entrenador: 'Ana García', especialidad: 'Entrenamiento funcional'
            }, historialSesiones: [{
                fecha: '10/10/2023',
                descripcion: 'Entrenamiento de piernas',
                entrenador: 'Carlos Ruiz',
                especialidad: 'Fuerza y acondicionamiento'
            }, {
                fecha: '08/10/2023',
                descripcion: 'Entrenamiento de pecho y espalda',
                entrenador: 'María López',
                especialidad: 'Hipertrofia'
            }, {
                fecha: '05/10/2023',
                descripcion: 'Entrenamiento de hombros y brazos',
                entrenador: 'Ana García',
                especialidad: 'Entrenamiento funcional'
            }]
        }, icon: faCalendarAlt
    }]

    const handleEdit = (title) => {
        setEditingSection(editingSection === title ? '' : title)
    }

    const renderProximaSesion = (sesion) => (<div className="proxima-sesion">
        <h3>Próxima Sesión</h3>
        <div className="sesion-info">
            <div>
                <FontAwesomeIcon icon={faCalendarAlt}/>
                <span>{sesion.fecha}</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faClock}/>
                <span>{sesion.hora}</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faUserTie}/>
                <span>{sesion.entrenador}</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faDumbbell}/>
                <span>{sesion.especialidad}</span>
            </div>
        </div>
    </div>)

    const renderHistorialSesiones = (historial) => (<div className="historial-sesiones">
        <h3>Historial de Sesiones</h3>
        <div className="sesiones-list">
            {historial.map((sesion, index) => (<div key={index} className="sesion-item">
                <div className="sesion-header">
                    <span>{sesion.fecha}</span>
                    <span>{sesion.entrenador}</span>
                </div>
                <div className="sesion-body">
                    <span>{sesion.descripcion}</span>
                    <span>{sesion.especialidad}</span>
                </div>
            </div>))}
        </div>
    </div>)

    return (<>
        <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
        <div className="perfil-container">
            <div className="perfil-content">
                <h1>Perfil de Entrenamiento</h1>
                <div>
                    {sections.map((section) => (<div key={section.title} className="section">
                        <div className="section-header">
                            <FontAwesomeIcon icon={section.icon} className="section-icon"/>
                            <h2 className="section-title">{section.title}</h2>
                        </div>
                        <div className="section-content">
                            {section.title === 'Próxima Sesión y Eventos' ? (<>
                                {renderProximaSesion(section.content.proximaSesion)}
                                {renderHistorialSesiones(section.content.historialSesiones)}
                            </>) : (<div>
                                {Object.entries(section.content).map(([key, value]) => (
                                    <div key={key} className="content-item">
                                        <span className="content-label">{key}:</span>
                                        {editingSection === section.title ? (<input
                                            type="text"
                                            value={Array.isArray(value) ? value.join(', ') : value}
                                            onChange={(e) => {
                                                console.log(`Updating ${key} to ${e.target.value}`)
                                            }}
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #e2e8f0',
                                                borderRadius: '0.25rem',
                                                fontSize: '1rem'
                                            }}
                                        />) : (<span className="content-value">
                              {Array.isArray(value) ? value.join(', ') : value}
                            </span>)}
                                    </div>))}
                            </div>)}
                            <div style={{marginTop: '24px', display: 'flex', justifyContent: 'flex-end'}}>
                                <button
                                    onClick={() => handleEdit(section.title)}
                                    className="edit-button"
                                >
                                    <FontAwesomeIcon
                                        icon={editingSection === section.title ? faCheck : faPencilAlt}
                                        className="edit-icon"/>
                                    <span>{editingSection === section.title ? 'Guardar' : 'Editar'}</span>
                                </button>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>
        </div>

        <style jsx>{`
            .perfil-container {
                min-height: 100vh;
                background-color: white;
                padding: 48px 16px;
            }

            .perfil-content {
                max-width: 64rem;
                margin: 0 auto;
            }

            h1 {
                font-size: 2.25rem;
                font-weight: 800;
                text-align: center;
                color: #1a202c;
                margin-bottom: 48px;
            }

            .section {
                background-color: white;
                border-radius: 0.75rem;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                margin-bottom: 32px;
            }

            .section:hover {
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            }

            .section-header {
                padding: 16px 24px;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
                align-items: center;
            }

            .section-icon {
                color: #3182ce;
                margin-right: 12px;
            }

            .section-title {
                font-size: 1.5rem;
                font-weight: 600;
                color: #2d3748;
            }

            .section-content {
                padding: 16px 24px;
            }

            .content-item {
                display: flex;
                flex-direction: column;
                padding: 8px 0;
                border-bottom: 1px solid #edf2f7;
            }

            .content-item:last-child {
                border-bottom: none;
            }

            .content-label {
                font-weight: 500;
                color: #4a5568;
                margin-bottom: 4px;
            }

            .content-value {
                color: #2d3748;
            }

            .edit-button {
                display: flex;
                align-items: center;
                background-color: #3182ce;
                color: white;
                padding: 8px 16px;
                border-radius: 9999px;
                transition: background-color 0.2s;
                border: none;
                cursor: pointer;
                font-size: 0.875rem;
            }

            .edit-button:hover {
                background-color: #2c5282;
            }

            .edit-icon {
                margin-right: 8px;
            }

            .proxima-sesion {
                background-color: #ebf8ff;
                padding: 16px;
                border-radius: 0.5rem;
                margin-bottom: 24px;
            }

            .proxima-sesion h3 {
                font-size: 1.125rem;
                font-weight: 600;
                color: #2c5282;
                margin-bottom: 12px;
            }

            .sesion-info {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;
            }

            .sesion-info div {
                display: flex;
                align-items: center;
                color: #2b6cb0;
            }

            .sesion-info svg {
                margin-right: 8px;
            }

            .historial-sesiones h3 {
                font-size: 1.125rem;
                font-weight: 600;
                color: #2d3748;
                margin-bottom: 12px;
            }

            .sesiones-list {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .sesion-item {
                background-color: #f7fafc;
                padding: 12px;
                border-radius: 0.375rem;
            }

            .sesion-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
            }

            .sesion-header span:first-child {
                font-weight: 500;
                color: #4a5568;
            }

            .sesion-header span:last-child {
                color: #2b6cb0;
                font-weight: 500;
            }

            .sesion-body {
                display: flex;
                justify-content: space-between;
            }

            .sesion-body span:first-child {
                color: #2d3748;
            }

            .sesion-body span:last-child {
                color: #718096;
                font-size: 0.875rem;
            }

            @media (min-width: 640px) {
                .content-item {
                    flex-direction: row;
                    align-items: center;
                }

                .content-label {
                    width: 33.333333%;
                    margin-bottom: 0;
                }

                .content-value {
                    width: 66.666667%;
                }
            }
        `}</style>

    </>)
}

import React from 'react';
import CoachCard from "../CoachCard";

const ListCoachCard = props => {

    const CoachList = [{
        name: 'Juan Perez',
        especialidad: 'Entrenador Personal',
        ubicacion: 'Bogota',
        tipoCita: 'Presencial',
        disponibilidad: 'Lunes, Martes, Miercoles',
        precio: '100.000'
    }, {
        name: 'Maria Rodriguez',
        especialidad: 'Nutricionista',
        ubicacion: 'Medellin',
        tipoCita: 'Virtual',
        disponibilidad: 'Lunes, Martes, Miercoles',
        precio: '100.000'
    }, {
        name: 'Pedro Gomez',
        especialidad: 'Psicologo',
        ubicacion: 'Cali',
        tipoCita: 'Presencial',
        disponibilidad: 'Lunes, Martes, Miercoles',
        precio: '100.000'
    }];


    return (<div className="mt-6 mb-8">
        {CoachList.map((coach, index) => (<CoachCard
            key={index}
        />))}
    </div>);
};

ListCoachCard.propTypes = {};

export default ListCoachCard;
import React, {useCallback, useEffect, useState} from 'react';

import {
    BicepsFlexed,
    BrainIcon,
    CalendarIcon,
    DumbbellIcon,
    HeartPulseIcon,
    SaladIcon,
    SearchIcon,
    SquareActivity,
    VideoIcon
} from "lucide-react";

import {Navbar} from "../../layouts";
import {useSelector} from "react-redux";

const Index = props => {
    const slides = [{
        image: '/images/coach.jpg',
        title: 'Asesoría Personalizada',
        description: 'Avanza hacia tus metas con la orientación directa de expertos en entrenamiento.'
    }, {
        image: '/images/training.jpg',
        title: 'Entrena en Persona',
        description: 'Conoce a tu coach cara a cara y potencia tu rendimiento con entrenamientos personalizados.'
    }, {
        image: '/images/running.jpg',
        title: 'Ruta al Éxito',
        description: 'Define y alcanza tus objetivos con planes que se ajustan perfectamente a tus necesidades.'
    }];
    const [currentSlide, setCurrentSlide] = useState(0);
    const {isAuthenticated, userRole} = useSelector((state) => state.auth);

    const nextSlide = useCallback(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, [slides.length]);

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 6000);

        return () => clearInterval(slideInterval);
    }, [nextSlide]);


    return (<>
        <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
        <div className="carousel">
            {slides.map((slide, index) => (<div
                className={`slide ${index === currentSlide ? 'active' : ''}`}
                style={{backgroundImage: `url(${slide.image})`}}
                key={index}
            >
                <div className="slide-content">
                    <h1 className="title">{slide.title}</h1>
                    <p className="description">{slide.description}</p>
                </div>
            </div>))}
            <button className="left-arrow"
                    onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}>&lt;</button>
            <button className="right-arrow" onClick={nextSlide}>&gt;</button>
        </div>
        <div>
            <div>
                <section className="hero">
                    <div className="container">
                        <div className="hero-content">
                            <h1>Encuentra tu coach ideal</h1>
                            <p>Conecta con entrenadores, psicólogos deportivos, nutricionistas y terapeutas para
                                sesiones en
                                línea a precios accesibles.</p>
                            <form>
                                <select>
                                    <option value="">Selecciona un servicio</option>
                                    <option value="entrenador">Entrenador personal</option>
                                    <option value="psicologo">Psicólogo deportivo</option>
                                    <option value="nutricionista">Nutricionista</option>
                                    <option value="terapeuta">Terapeuta</option>
                                </select>
                                <button type="submit">
                                    <SearchIcon size={16}/>
                                    Buscar
                                </button>
                            </form>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <h2 className="section-title">Nuestros servicios</h2>
                        <div className="card-grid">
                            {[{
                                icon: DumbbellIcon,
                                title: "Entrenadores personales",
                                description: "Alcanza tus metas fitness con entrenadores expertos."
                            }, {
                                icon: BrainIcon,
                                title: "Psicólogos deportivos",
                                description: "Mejora tu rendimiento mental y supera obstáculos."
                            }, {
                                icon: SaladIcon,
                                title: "Nutricionistas",
                                description: "Optimiza tu dieta para un mejor rendimiento y salud."
                            }, {
                                icon: HeartPulseIcon,
                                title: "Terapeutas",
                                description: "Recupera tu bienestar físico y mental."
                            }, {
                                icon: SquareActivity,
                                title: "Fisioterapeutas deportivos",
                                description: "Especializados en la rehabilitación y prevención de lesiones deportivas."
                            }, {
                                icon: BicepsFlexed,
                                title: "Preparadores físicos",
                                description: "Enfocados en mejorar la condición física y el rendimiento atlético."
                            }

                            ].map((service, index) => (<div key={index} className="card">
                                <div className="card-icon">
                                    <service.icon size={48}/>
                                </div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>))}
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <h2 className="section-title">Cómo funciona</h2>
                        <div className="card-grid">
                            {[{
                                icon: SearchIcon,
                                title: "Busca",
                                description: "Encuentra el profesional que mejor se adapte a tus necesidades utilizando nuestros filtros avanzados."
                            }, {
                                icon: CalendarIcon,
                                title: "Agenda",
                                description: "Reserva una sesión en línea en el horario que te convenga con nuestro sistema de reservas fácil de usar."
                            }, {
                                icon: VideoIcon,
                                title: "Conéctate",
                                description: "Disfruta de tu sesión en línea y alcanza tus objetivos con nuestro sistema de videoconferencia integrado."
                            }].map((step, index) => (<div key={index} className="card">
                                <div className="card-icon">
                                    <step.icon size={48}/>
                                </div>
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>))}
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <h2 className="section-title">Testimonios</h2>
                        <div className="card-grid">
                            {[1, 2, 3].map((i) => (<div key={i} className="card">
                                <p>CoachConnect ha transformado mi enfoque del fitness. Mi entrenador personal en línea
                                    me
                                    ha ayudado a alcanzar metas que nunca pensé que fueran posibles.</p>
                                <div className="professional-info">
                                    <div className="circle-image-info">
                                        <img src={`/images/testimonio1.jpg`}
                                             alt={`Usuario ${i}`}/>
                                    </div>

                                    <div>
                                        <p>Usuario {i}</p>
                                        <p>Cliente satisfecho</p>
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>
                </section>
            </div>
            <footer className="footer">
                <div className="container">
                    <p>&copy; 2023 CoachConnect. Todos los derechos reservados.</p>
                </div>
            </footer>
        </div>

        <style jsx>{`
            body {
                font-family: 'Inter', sans-serif;
                line-height: 1.6;
                color: #1a202c;
                background-color: #f7fafc;
            }

            .container {
                width: 100%;
                max-width: 90%;
                margin: 0 auto;
                padding: 0 20px;
            }


            /* Estilos del héroe */
            .hero {
                background-color: #fff;
                color: #2d3748;
                text-align: center;
                padding: 6rem 0;
                position: relative;
                overflow: hidden;
            }

            .hero::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: radial-gradient(circle at 20% 20%, rgba(237, 242, 247, 0.7) 0%, transparent 25%),
                radial-gradient(circle at 80% 80%, rgba(237, 242, 247, 0.7) 0%, transparent 25%);
                pointer-events: none;
            }

            .hero-content {
                position: relative;
                z-index: 1;
                max-width: 600px;
                margin: 0 auto;
            }

            .hero h1 {
                font-size: 3rem;
                font-weight: 700;
                margin-bottom: 1rem;
                color: #2d3748;
            }

            .hero p {
                font-size: 1.2rem;
                margin-bottom: 2rem;
                color: #4a5568;
            }

            .hero form {
                display: flex;
                gap: 1rem;
                justify-content: center;
            }

            .hero select, .hero button {
                padding: 0.75rem 1.5rem;
                border-radius: 0.375rem;
                border: 1px solid #e2e8f0;
                font-size: 1rem;
                transition: all 0.3s ease;
            }

            .hero select {
                background-color: #fff;
                color: #2d3748;
                min-width: 200px;
            }

            .hero button {
                background-color: #3182ce;
                color: #fff;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                border: none;
            }

            .hero button:hover {
                background-color: #2c5282;
            }

            /* Estilos de las secciones */
            .section {
                padding: 6rem 0;
                background-color: #fff;
            }

            .section-title {
                font-size: 2.5rem;
                text-align: center;
                margin-bottom: 3rem;
                color: #2d3748;
            }

            .card-grid {
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
                gap: 2rem;
            }

            .card {
                background-color: #fff;
                border-radius: 0.5rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
                padding: 2rem;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .card:hover {
                transform: translateY(-5px);
                box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
            }

            .card-icon {
                font-size: 3rem;
                color: #3182ce;
                margin-bottom: 1.5rem;
            }

            .card h3 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                color: #2d3748;
            }

            .card p {
                color: #4a5568;
            }

            /* Estilos de la página de búsqueda */
            .search-filters {
                display: flex;
                gap: 1rem;
                margin-bottom: 2rem;
                flex-wrap: wrap;
            }

            .search-filters input,
            .search-filters select,
            .search-filters button {
                padding: 0.75rem 1.5rem;
                border-radius: 0.375rem;
                border: 1px solid #e2e8f0;
                font-size: 1rem;
            }

            .search-filters input,
            .search-filters select {
                background-color: #fff;
                color: #2d3748;
                min-width: 200px;
            }

            .search-filters button {
                background-color: #3182ce;
                color: #fff;
                cursor: pointer;
                transition: background-color 0.3s ease;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                border: none;
            }

            .search-filters button:hover {
                background-color: #2c5282;
            }

            .professional-card {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .professional-info {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;
            }

            .professional-rating {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-bottom: 1rem;
                color: #f6ad55;
            }

            .professional-card button {
                background-color: #3182ce;
                color: #fff;
                border: none;
                padding: 0.75rem 1.5rem;
                border-radius: 0.375rem;
                cursor: pointer;
                transition: background-color 0.3s ease;
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }

            .professional-card button:hover {
                background-color: #2c5282;
            }

            .carousel {
                position: relative;
                width: 100%;
                height: 500px;
                overflow: hidden;
            }

            .slide {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                position: absolute;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
            }

            .slide.active {
                opacity: 1;
            }

            .slide-content {
                position: absolute;
                bottom: 50px;
                left: 50px;
                color: white;
                font-size: 2em;
                font-weight: bold;
                text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
            }

            .left-arrow, .right-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: rgba(0, 0, 0, 0.5);
                border: none;
                color: white;
                font-size: 24px;
                cursor: pointer;
                padding: 10px 20px;
            }

            .left-arrow {
                left: 10px;
            }

            .right-arrow {
                right: 10px;
            }

            button:focus {
                outline: none;
            }

            .title {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }


            .description {
                font-size: .8em;
                color: #fff;
                text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
                margin-top: 1rem;
            }

            .circle-image-info {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
            }

            .footer {
                background-color: #f7fafc;
                color: #4a5568;
                padding: 3rem 0;
                text-align: center;
                border-top: 1px solid #e2e8f0;
            }


            /* Estilos responsivos */
            @media (max-width: 768px) {
                .nav-links {
                    display: none;
                }

                .hero h1 {
                    font-size: 2.5rem;
                }

                .section-title {
                    font-size: 2rem;
                }

                .hero form {
                    flex-direction: column;
                }

                .search-filters {
                    flex-direction: column;
                }
            }

        `}</style>
    </>);
};

export default Index;
// src/components/Logout.js
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {logout} from '../../store/authSlice';
import {useNavigate} from 'react-router-dom';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());

        navigate('/login');
    }, [dispatch, navigate]);

    return (<div>
        <h1>Logging out...</h1>
    </div>);
};

export default Logout;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const API_URL = 'http://localhost:8080';

const initialState = {
    isRegistering: false, registerSuccess: false, registerError: null,
};

const convertToFormData = (data) => {
    const formData = new FormData();
    const {profilePicture, ...rest} = data;
    formData.append('data', JSON.stringify(rest));
    if (profilePicture) {
        formData.append('image', profilePicture);
    }
    return formData;
};

export const registerCoach = createAsyncThunk('register/registerCoach', async (newCoach, {rejectWithValue}) => {
    try {
        const formData = convertToFormData(newCoach);

        const response = await fetch(`${API_URL}/api/coach`, {
            method: 'POST', body: formData,
        });

        const data = await response.json();

        if (!response.ok) {
            return rejectWithValue(data.message || 'Coach registration failed');
        }

        return data;
    } catch (error) {
        return rejectWithValue(error.message || 'Coach registration failed');
    }
});

const registerCoachSlice = createSlice({
    name: 'register', initialState, reducers: {
        clearRegisterState(state) {
            state.isRegistering = false;
            state.registerSuccess = false;
            state.registerError = null;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(registerCoach.pending, (state) => {
                state.isRegistering = true;
                state.registerError = null;
                state.registerSuccess = false;
            })
            .addCase(registerCoach.fulfilled, (state) => {
                state.isRegistering = false;
                state.registerSuccess = true;
            })
            .addCase(registerCoach.rejected, (state, action) => {
                state.isRegistering = false;
                state.registerError = action.payload;
            });
    },
});

export const {clearRegisterState} = registerCoachSlice.actions;

export default registerCoachSlice.reducer;

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBed,
    faBrain,
    faDumbbell,
    faExclamationTriangle,
    faHeartbeat,
    faProjectDiagram
} from '@fortawesome/free-solid-svg-icons';
import {Navbar} from "../../layouts";
import {useSelector} from "react-redux";

const Card = ({children, className = ''}) => (<div className={`card ${className}`}>
    {children}
</div>);

const ProgressCircle = ({value, max, color, size = 100}) => {
    const percentage = (value / max) * 100;
    const strokeWidth = size * 0.1;
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (<svg height={size} width={size} className="progress-circle">
        <circle
            stroke="#e0e0e0"
            strokeWidth={strokeWidth}
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
        />
        <circle
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
        />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="progress-text">
            {`${Math.round(percentage)}%`}
        </text>
    </svg>);
};

export default function AIAnalysisModern() {

    const {isAuthenticated, userRole} = useSelector((state) => state.auth);


    return (<>
            <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
            <div className="ai-analysis-container">
                <h1 className="main-title">Análisis de IA Personalizado</h1>

                <section className="overview-section">
                    <h2>Resumen de Progreso</h2>
                    <div className="progress-grid">
                        <Card className="progress-card">
                            <ProgressCircle value={1.5} max={2} color="#3498db"/>
                            <h3>Pérdida de peso</h3>
                            <p className="progress-detail">1.5 kg / 2 kg</p>
                        </Card>
                        <Card className="progress-card">
                            <ProgressCircle value={60} max={100} color="#2ecc71"/>
                            <h3>Aumento de fuerza</h3>
                            <p className="progress-detail">60% completado</p>
                        </Card>
                        <Card className="progress-card">
                            <ProgressCircle value={40} max={100} color="#e74c3c"/>
                            <h3>Mejora de flexibilidad</h3>
                            <p className="progress-detail">40% completado</p>
                        </Card>
                    </div>
                </section>

                <section className="safety-section">
                    <h2>Evaluación de Seguridad</h2>
                    <Card className="safety-card">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faHeartbeat} className="section-icon"/>
                            <h3>Rango de Pérdida de Peso Saludable</h3>
                        </div>
                        <div className="card-content">
                            <p>Tu pérdida de peso de 1.5 kg en el último mes está dentro del rango saludable para tu
                                edad
                                (30 años). Continúa así para mantener tu salud óptima.</p>
                            <ul className="health-indicators">
                                <li><span>Frecuencia cardíaca en reposo:</span> <span
                                    className="value excellent">62 bpm</span></li>
                                <li><span>Presión arterial:</span> <span className="value normal">118/78 mmHg</span>
                                </li>
                                <li><span>IMC:</span> <span className="value normal">24.5</span></li>
                            </ul>
                        </div>
                    </Card>
                </section>

                <section className="comparison-section">
                    <h2>Comparación con Promedios</h2>
                    <Card className="comparison-card">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faProjectDiagram} className="section-icon"/>
                            <h3>Tu Progreso vs. Promedio</h3>
                        </div>
                        <div className="card-content">
                            <p>En promedio, usuarios de tu edad (30 años) pierden entre 1-2 kg al mes. Tu progreso está
                                ligeramente por encima del promedio, lo cual es excelente.</p>
                            <div className="comparison-chart">
                                <div className="chart-bar">
                                    <div className="bar-label">Pérdida de peso</div>
                                    <div className="bar-container">
                                        <div className="bar-fill" style={{width: '120%'}}></div>
                                        <div className="bar-average"></div>
                                    </div>
                                    <div className="bar-value">20% por encima del promedio</div>
                                </div>
                                <div className="chart-bar">
                                    <div className="bar-label">Ganancia de fuerza</div>
                                    <div className="bar-container">
                                        <div className="bar-fill" style={{width: '105%'}}></div>
                                        <div className="bar-average"></div>
                                    </div>
                                    <div className="bar-value">5% por encima del promedio</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </section>

                <section className="recommendations-section">
                    <h2>Recomendaciones Personalizadas</h2>
                    <Card className="recommendations-card">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faDumbbell} className="section-icon"/>
                            <h3>Ajustes Sugeridos</h3>
                        </div>
                        <div className="card-content">
                            <p>Basado en tu edad (30 años) y estado de salud actual:</p>
                            <ul className="recommendations-list">
                                <li>Incorpora ejercicios de resistencia dos veces por semana para mantener la masa
                                    muscular.
                                </li>
                                <li>Aumenta la intensidad de tus entrenamientos de fuerza en un 10%.</li>
                                <li>Considera reducir tu ingesta calórica diaria en 200 kcal para acelerar la pérdida de
                                    peso de forma segura.
                                </li>
                            </ul>
                        </div>
                    </Card>
                </section>

                <section className="alerts-section">
                    <h2>Alertas de Progreso</h2>
                    <Card className="alerts-card">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="section-icon warning"/>
                            <h3>Puntos de Atención</h3>
                        </div>
                        <div className="card-content">
                            <p className="warning">Tu frecuencia de entrenamientos ha aumentado un 20%, lo que podría
                                llevar
                                a sobreentrenamiento. Recomendamos incluir más días de descanso.</p>
                        </div>
                    </Card>
                </section>

                <section className="factors-section">
                    <h2>Factores Adicionales</h2>
                    <div className="factors-grid">
                        <Card className="factor-card">
                            <FontAwesomeIcon icon={faBed} className="factor-icon"/>
                            <h3>Sueño</h3>
                            <p>Calidad mejorada en 1 hora/noche</p>
                        </Card>
                        <Card className="factor-card">
                            <FontAwesomeIcon icon={faBrain} className="factor-icon"/>
                            <h3>Estrés</h3>
                            <p>Niveles elevados esta semana</p>
                        </Card>
                    </div>
                </section>

            </div>
            <style jsx>{`
                .ai-analysis-container {
                    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                    max-width: 900px;
                    margin: 0 auto;
                    padding: 40px 20px;
                    background-color: #f8f9fa;
                    color: #333;
                }

                .main-title {
                    font-size: 2.5rem;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 40px;
                    color: #2c3e50;
                }

                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                    margin-bottom: 24px;
                    color: #2c3e50;
                }

                h3 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-bottom: 16px;
                    color: #34495e;
                }

                .card {
                    background-color: #ffffff;
                    border-radius: 12px;
                    padding: 24px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    margin-bottom: 24px;
                    transition: all 0.3s ease;
                }

                .card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                }

                .card-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                }

                .card-content {
                    font-size: 1rem;
                    line-height: 1.6;
                }

                .progress-grid, .factors-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                    gap: 24px;
                    margin-bottom: 40px;
                }

                .progress-card, .factor-card {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .progress-circle {
                    margin-bottom: 16px;
                }

                .progress-text {
                    font-size: 1.2rem;
                    font-weight: 700;
                    fill: #333;
                }

                .progress-detail {
                    color: #7f8c8d;
                    font-size: 0.9rem;
                }

                .section-icon, .factor-icon {
                    font-size: 1.5rem;
                    color: #3498db;
                    margin-right: 16px;
                }

                .health-indicators, .recommendations-list {
                    list-style-type: none;
                    padding: 0;
                    margin-top: 16px;
                }

                .health-indicators li, .recommendations-list li {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;
                }

                .value {
                    font-weight: 600;
                }

                .excellent {
                    color: #2ecc71;
                }

                .normal {
                    color: #3498db;
                }

                .warning {
                    color: #e74c3c;
                }

                .comparison-chart {
                    margin-top: 24px;
                }

                .chart-bar {
                    margin-bottom: 16px;
                }

                .bar-label {
                    font-size: 0.9rem;
                    color: #7f8c8d;
                    margin-bottom: 8px;
                }

                .bar-container {
                    height: 12px;
                    background-color: #ecf0f1;
                    border-radius: 6px;
                    overflow: hidden;
                    position: relative;
                }

                .bar-fill {
                    height: 100%;
                    background-color: #3498db;
                    border-radius: 6px;
                }

                .bar-average {
                    height: 100%;
                    width: 2px;
                    background-color: #e74c3c;
                    position: absolute;
                    left: 100%;
                    top: 0;
                }

                .bar-value {
                    font-size: 0.8rem;
                    color: #7f8c8d;
                    margin-top: 8px;
                }

                section {
                    margin-bottom: 40px;
                }

                @media (max-width: 768px) {
                    .main-title {
                        font-size: 2rem;
                    }

                    h2 {
                        font-size: 1.5rem;
                    }

                    .card {
                        padding: 20px;
                    }
                }
            `}</style>
        </>


    );
}
import React from 'react';
import {CoachSelector, ListCoachCard} from "../../components";
import {Navbar} from "../../layouts";


const Index = props => {
    return (<>
            <style jsx>{`
                .container-encuentra {
                    margin-top: 6rem;
                }
            `}</style>
            <div className="container-encuentra">
                <Navbar/>
                <CoachSelector/>
                <ListCoachCard/>
            </div>
        </>

    );
};


export default Index;
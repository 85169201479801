import React from 'react';
import {Navbar} from "../../layouts";
import RegistrarseCoach from "./RegistrarseCoach";


function Page() {
    return (<div>
        <Navbar/>
        <RegistrarseCoach/>
    </div>);
}

export default Page;
import React, {useState} from 'react';

export default function Component() {
    const [selectedTimezone, setSelectedTimezone] = useState("America/Mexico_City");
    const [isTimezoneSelectorOpen, setIsTimezoneSelectorOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [showAllHours, setShowAllHours] = useState(false);

    const timezones = ["America/Mexico_City", "America/New_York", "America/Los_Angeles", "Europe/London", "Europe/Paris", "Asia/Tokyo", "Australia/Sydney"];

    const generateTimeSlots = (date) => {
        const slots = [];
        for (let hour = 9; hour < 21; hour++) {
            const time = `${hour.toString().padStart(2, '0')}:00`;
            let status = 'available';
            if (Math.random() < 0.3) {
                status = 'booked';
            }
            slots.push({time, status});
        }
        return slots;
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('es-ES', {weekday: 'short', month: 'short', day: 'numeric'});
    };

    const changeDate = (days) => {
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() + days);
        setStartDate(newStartDate);
    };

    const getDates = () => {
        const dates = [];
        for (let i = 0; i < 4; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            dates.push(date);
        }
        return dates;
    };

    const handleCoachClick = () => {
        console.log('Redirecting to coach profile...');
    };

    return (<div className="profile-container">
        <style jsx>
            {`
                .profile-container {
                    max-width: 1024px;
                    margin: 0 auto;
                    padding: 28px;
                    background-color: white;
                    border-radius: 12px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    transition: box-shadow 0.3s ease;
                }

                .profile-container:hover {
                    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
                }

                .profile-content {
                    display: flex;
                    flex-direction: row;
                    gap: 28px;
                }

                .profile-info {
                    flex: 1;
                }

                .profile-header {
                    display: flex;
                    margin-bottom: 28px;
                    align-items: center;
                    cursor: pointer;
                    transition: transform 0.3s ease;
                }

                .profile-header:hover {
                    transform: scale(1.02);
                }

                .profile-image {
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 20px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .profile-image:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
                }

                .profile-name {
                    font-size: 28px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    color: #1a202c;
                    transition: color 0.3s ease;
                }

                .profile-name:hover {
                    color: #2b6cb0;
                }

                .profile-title {
                    font-size: 16px;
                    color: #4a5568;
                    margin-bottom: 4px;
                }

                .profile-specialty {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;
                }

                .info-button {
                    background: none;
                    border: none;
                    color: #a0aec0;
                    cursor: pointer;
                    transition: color 0.3s, transform 0.3s;
                    margin-left: 8px;
                }

                .info-button:hover {
                    color: #2b6cb0;
                    transform: scale(1.1);
                }

                .profile-reviews {
                    font-size: 14px;
                    color: #4299e1;
                    text-decoration: none;
                    transition: color 0.3s;
                }

                .profile-reviews:hover {
                    color: #2b6cb0;
                }

                .profile-tags {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    gap: 8px;
                }

                .tag {
                    display: inline-block;
                    background-color: #f7fafc;
                    color: #4a5568;
                    border-radius: 9999px;
                    padding: 6px 12px;
                    font-size: 14px;
                    font-weight: 500;
                    transition: background-color 0.3s, transform 0.3s;
                }

                .tag:hover {
                    background-color: #edf2f7;
                    transform: translateY(-2px);
                }

                .profile-languages {
                    font-size: 14px;
                    color: #4a5568;
                    margin-bottom: 20px;
                }

                .bold {
                    font-weight: 600;
                }

                .profile-sessions {
                    display: flex;
                    justify-content: space-between;
                    border-top: 1px solid #e2e8f0;
                    border-bottom: 1px solid #e2e8f0;
                    padding: 16px 0;
                }

                .session {
                    margin-bottom: 12px;
                }

                .session-title {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                .session-description {
                    font-size: 14px;
                    color: #4a5568;
                }

                .price-factors {
                    font-size: 14px;
                    color: #4299e1;
                    background: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    text-decoration: underline;
                    transition: color 0.3s;
                }

                .price-factors:hover {
                    color: #2b6cb0;
                }

                .session-price {
                    text-align: right;
                }

                .price {
                    font-size: 24px;
                    font-weight: 600;
                    color: #1a202c;
                }

                .booking-section {
                    flex: 1;
                    border-left: 1px solid #e2e8f0;
                    padding-left: 28px;
                }

                .timezone-selector {
                    position: relative;
                    margin-bottom: 20px;
                }

                .timezone-button {
                    width: 100%;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                    color: #4a5568;
                    background-color: white;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    padding: 12px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                }

                .timezone-button:hover {
                    background-color: #f7fafc;
                }

                .dropdown-arrow {
                    float: right;
                    transition: transform 0.3s ease;
                }

                .dropdown-arrow.open {
                    transform: rotate(180deg);
                }

                .timezone-list {
                    position: absolute;
                    z-index: 10;
                    width: 100%;
                    max-height: 240px;
                    overflow-y: auto;
                    background-color: white;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    list-style-type: none;
                    padding: 0;
                    margin: 4px 0 0 0;
                }

                .timezone-option {
                    padding: 12px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .timezone-option:hover {
                    background-color: #edf2f7;
                }

                .timezone-option.selected {
                    background-color: #4299e1;
                    color: white;
                }

                .calendar-container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                }

                .nav-buttons {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                }

                .nav-button {
                    background: none;
                    border: none;
                    color: #4299e1;
                    font-size: 16px;
                    cursor: pointer;
                    padding: 8px;
                    transition: all 0.3s ease;
                    border-radius: 8px;
                }

                .nav-button:hover {
                    background-color: #ebf8ff;
                    color: #2b6cb0;
                }

                .calendar {
                    border: 1px solid #e2e8f0;
                    border-radius: 12px;
                    overflow: hidden;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                }

                .date-header {
                    display: flex;
                    background-color: #f7fafc;
                    border-bottom: 1px solid #e2e8f0;
                }

                .date-header-cell {
                    flex: 1;
                    padding: 16px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    color: #4a5568;
                }

                .time-slots {
                    display: flex;
                }

                .day-column {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 12px;
                    border-right: 1px solid #e2e8f0;
                }

                .day-column:last-child {
                    border-right: none;
                }

                .time-slot {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    background-color: white;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    text-align: center;
                }

                .time-slot:hover:not(.booked) {
                    background-color: #ebf8ff;
                    border-color: #4299e1;
                }

                .time-slot.booked {
                    background-color: #f7fafc;
                    color: #a0aec0;
                    cursor: not-allowed;
                }

                .show-more-button {
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #4299e1;
                    background: none;
                    border: none;
                    font-size: 14px;
                    cursor: pointer;
                    margin-top: 16px;
                    transition: all 0.3s ease;
                    padding: 8px;
                    border-radius: 8px;
                }

                .show-more-button:hover {
                    background-color: #ebf8ff;
                    color: #2b6cb0;
                }
            `}
        </style>
        <div className="profile-content">
            <div className="profile-info">
                <div
                    className="profile-header"
                    onClick={handleCoachClick}
                >
                    <img
                        src="/images/entrenador.jpg?height=120&width=120"
                        alt="Foto de Alex Fitness"
                        className="profile-image"
                    />
                    <div>
                        <h2 className="profile-name">
                            Alex Fitness
                        </h2>
                        <p className="profile-title">Entrenador personal & Nutricionista deportivo</p>
                        <p className="profile-title">NSCA-CSCS</p>
                        <div className="profile-specialty">
                            <p className="profile-title">Hipertrofia y pérdida de grasa</p>
                            <button
                                className="info-button"
                                aria-label="Más información sobre la especialidad"
                            >
                                ⓘ
                            </button>
                        </div>
                        <a
                            href="#opiniones"
                            className="profile-reviews"
                        >
                            92 reseñas
                        </a>
                    </div>
                </div>

                <div className="profile-tags">
                    {['Entrenamiento de fuerza', 'Nutrición deportiva', 'HIIT', 'Recuperación', 'Suplementación'].map((tag, index) => (
                        <span
                            key={index}
                            className="tag"
                        >
                                {tag}
                            </span>))}
                </div>

                <div className="profile-languages">
                    <p><span className="bold">Idiomas:</span> Español, Inglés</p>
                    <p><span className="bold">Experiencia:</span> 8 años</p>
                </div>

                <div className="profile-sessions">
                    <div>
                        <div className="session">
                            <h3 className="session-title">Sesión virtual - 60 min.</h3>
                            <p className="session-description">Precio fijo</p>
                        </div>
                        <div className="session">
                            <h3 className="session-title">Sesión presencial - 90 min.</h3>
                            <p className="session-description">Precio variable</p>
                            <button
                                className="price-factors"
                            >
                                Ver factores de precio
                            </button>
                        </div>
                    </div>
                    <div className="session-price">
                        <span className="price">$899 MXN</span>
                        <p className="session-description">Sesión virtual</p>
                    </div>
                </div>
            </div>

            <div className="booking-section">
                <div className="timezone-selector">
                    <button
                        className="timezone-button"
                        onClick={() => setIsTimezoneSelectorOpen(!isTimezoneSelectorOpen)}
                        aria-haspopup="listbox"
                        aria-expanded={isTimezoneSelectorOpen}
                    >
                        {selectedTimezone}
                        <span className={`dropdown-arrow ${isTimezoneSelectorOpen ? 'open' : ''}`}>
                                ▼
                            </span>
                    </button>
                    {isTimezoneSelectorOpen && (<ul
                        className="timezone-list"
                        tabIndex={-1}
                        role="listbox"
                        aria-labelledby="timezone-selector"
                        aria-activedescendant={selectedTimezone}
                    >
                        {timezones.map((timezone) => (<li
                            key={timezone}
                            className={`timezone-option ${timezone === selectedTimezone ? 'selected' : ''}`}
                            id={timezone}
                            role="option"
                            aria-selected={timezone === selectedTimezone}
                            onClick={() => {
                                setSelectedTimezone(timezone);
                                setIsTimezoneSelectorOpen(false);
                            }}
                        >
                            {timezone}
                        </li>))}
                    </ul>)}
                </div>

                <div className="calendar-container">
                    <div className="nav-buttons">
                        <button
                            onClick={() => changeDate(-4)}
                            className="nav-button"
                            aria-label="4 días anteriores"
                        >
                            ◀ Anterior
                        </button>
                        <button
                            onClick={() => changeDate(4)}
                            className="nav-button"
                            aria-label="4 días siguientes"
                        >
                            Siguiente ▶
                        </button>
                    </div>
                    <div className="calendar">
                        <div className="date-header">
                            {getDates().map((date, index) => (<div key={index} className="date-header-cell">
                                {formatDate(date)}
                            </div>))}
                        </div>
                        <div className="time-slots">
                            {getDates().map((date, dateIndex) => (<div
                                key={dateIndex}
                                className={`day-column ${dateIndex === 3 ? 'day-column-last' : ''}`}
                            >
                                {generateTimeSlots(date).slice(0, showAllHours ? undefined : 6).map((slot, slotIndex) => (
                                    <button
                                        key={slotIndex}
                                        className={`time-slot ${slot.status === 'booked' ? 'booked' : ''}`}
                                        disabled={slot.status !== 'available'}
                                        aria-label={`Reservar sesión el ${formatDate(date)} a las ${slot.time}${slot.status !== 'available' ? ', no disponible' : ''}`}
                                        onClick={() => {
                                            if (slot.status === 'available') {
                                                console.log(`Reservar sesión el ${formatDate(date)} a las ${slot.time}`);
                                            }
                                        }}
                                    >
                                        {slot.time}
                                    </button>))}
                            </div>))}
                        </div>
                    </div>
                </div>

                <button
                    className="show-more-button"
                    onClick={() => setShowAllHours(!showAllHours)}
                >
                    {showAllHours ? 'Ver menos horarios' : 'Ver más horarios'}
                </button>
            </div>
        </div>
    </div>);
}
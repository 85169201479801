// src/utils/errorHandler.js

const resizeObserverErrRe = /^ResizeObserver loop completed with undelivered notifications./;


const resizeObserverLoopErrRe = /^ResizeObserver loop limit exceeded/;

window.addEventListener('error', (event) => {
    if (resizeObserverLoopErrRe.test(event.message)) {
        event.stopImmediatePropagation();
    }
});


// Guarda las referencias originales de console.error y console.warn
const originalError = console.error;
const originalWarn = console.warn;

// Sobrescribe console.error para filtrar el error específico
console.error = (...args) => {
    if (resizeObserverErrRe.test(args[0])) {
        return;
    }
    originalError.call(console, ...args);
};

// Sobrescribe console.warn para filtrar el error específico
console.warn = (...args) => {
    if (resizeObserverErrRe.test(args[0])) {
        return;
    }
    originalWarn.call(console, ...args);
};




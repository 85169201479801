import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faFacebookF, faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faEye, faEyeSlash, faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import {Navbar} from "../../layouts";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {clearRegisterState, registerUser} from '../../store/userRegisterSlice';
import {Button, Message, Modal, useToaster} from 'rsuite';

export default function RegisterComponent() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '', email: '', password: '', confirmPassword: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toaster = useToaster();

    const {isRegistering, registerError, registerSuccess} = useSelector(state => state.userRegister);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            toaster.push(<Message type="error" closable>
                Las contraseñas no coinciden.
            </Message>, {placement: 'topEnd', duration: 5000});
            return;
        }

        dispatch(registerUser({
            name: formData.nombre, email: formData.email, password: formData.password
        }));
    };

    useEffect(() => {
        if (registerSuccess) {
            setModalOpen(true);
            dispatch(clearRegisterState());
        }
    }, [registerSuccess, dispatch]);

    useEffect(() => {
        if (registerError) {
            toaster.push(<Message type="error" closable>
                {registerError}
            </Message>, {placement: 'topEnd', duration: 5000});
            dispatch(clearRegisterState());
        }
    }, [registerError, dispatch, toaster]);

    const handleModalClose = () => {
        setModalOpen(false);
        navigate('/login');
    };

    return (<div>
        <Navbar/>
        <div className="registroContainer">
            <div className="registroForm">
                <h2 className="title">Crea tu cuenta</h2>
                <p className="subtitle">Únete a nuestra comunidad de entrenamiento.</p>
                <form onSubmit={handleSubmit}>
                    <div className="formGroup">
                        <label htmlFor="nombre" className="label">
                            <FontAwesomeIcon icon={faUser} className="icon"/> Nombre completo
                        </label>
                        <input
                            type="text"
                            id="nombre"
                            name="nombre"
                            placeholder="Tu nombre completo"
                            required
                            className="input"
                            value={formData.nombre}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="email" className="label">
                            <FontAwesomeIcon icon={faEnvelope} className="icon"/> Correo electrónico
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="tu@email.com"
                            required
                            className="input"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="password" className="label">
                            <FontAwesomeIcon icon={faLock} className="icon"/> Contraseña
                        </label>
                        <div className="passwordInput">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                placeholder="Crea una contraseña segura"
                                required
                                className="input"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className="togglePassword"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="confirmPassword" className="label">
                            <FontAwesomeIcon icon={faLock} className="icon"/> Confirmar contraseña
                        </label>
                        <div className="passwordInput">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirma tu contraseña"
                                required
                                className="input"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className="togglePassword"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                    </div>
                    <div className="termsConditions">
                        <label className="termsLabel">
                            <input type="checkbox" required className="checkbox"/>
                            <span className="checkmark"></span>
                            <span className="termsText">
                                    Acepto los <a href="#" className="termsLink">términos y condiciones</a>
                                </span>
                        </label>
                    </div>
                    <button type="submit" className="submitBtn" disabled={isRegistering}>
                        {isRegistering ? 'Registrando...' : 'Crear cuenta'}
                    </button>
                </form>
                <div className="socialRegistro">
                    <p>O regístrate con</p>
                    <div className="socialButtons">
                        <button className="socialBtn" aria-label="Registrarse con Facebook">
                            <FontAwesomeIcon icon={faFacebookF}/>
                        </button>
                        <button className="socialBtn" aria-label="Registrarse con Google">
                            <FontAwesomeIcon icon={faGoogle}/>
                        </button>
                        <button className="socialBtn" aria-label="Registrarse con Apple">
                            <FontAwesomeIcon icon={faApple}/>
                        </button>
                    </div>
                </div>
                <p className="loginPrompt">
                    ¿Ya tienes una cuenta? <a onClick={() => navigate('/login')} className="termsLink">Inicia
                    sesión</a>
                </p>
            </div>
        </div>

        <Modal size="sm" open={modalOpen} onClose={handleModalClose}>
            <Modal.Header>
                <Modal.Title>Registro Exitoso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Hemos enviado un correo electrónico a <strong>{formData.email}</strong> para continuar con el
                registro.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleModalClose} appearance="primary">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>

        <style jsx>{`
            .registroContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: calc(100vh - 4rem);
                background-color: #f5f5f5;
                font-family: Arial, sans-serif;
                padding-top: 5rem;
            }

            .registroForm {
                background-color: white;
                border-radius: 0.5rem;
                box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
                width: 100%;
                max-width: 31.25rem;
                padding: 0 5rem 2rem 5rem; 
                margin-bottom: 2rem;
            }

            .title {
                margin-top: 2rem;
                color: #333;
                font-size: 1.75rem;
                margin-bottom: 0.25rem;
                text-align: center;
            }

            .subtitle {
                color: #666;
                text-align: center;
                margin-bottom: 1rem;
                font-size: 0.95rem;
            }

            .formGroup {
                margin-bottom: 0.8rem;
            }

            .label {
                display: block;
                margin-bottom: 0.125rem;
                color: #333;
                font-weight: 600;
                font-size: 0.85rem;
            }

            .icon {
                margin-right: 0.5rem;
                color: #666;
            }

            .input {
                width: 100%;
                padding: 0.6rem;
                border: 0.0625rem solid #ddd;
                border-radius: 0.25rem;
                font-size: 0.95rem;
                transition: border-color 0.3s ease;
            }

            .input:focus {
                border-color: #333;
                outline: none;
            }

            .passwordInput {
                position: relative;
            }

            .togglePassword {
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                color: #666;
                cursor: pointer;
                font-size: 0.9rem;
            }

            .termsConditions {
                display: flex;
                align-items: center;
                font-size: 0.85rem;
                margin-top: 0.8rem;
            }

            .termsLabel {
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                padding-left: 1.5625rem;
                user-select: none;
            }

            .checkbox {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 1.125rem;
                width: 1.125rem;
                background-color: #eee;
                border-radius: 0.1875rem;
            }

            .checkbox:checked ~ .checkmark {
                background-color: #333;
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .checkbox:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 0.3125rem;
                top: 0.125rem;
                width: 0.25rem;
                height: 0.5rem;
                border: solid white;
                border-width: 0 0.125rem 0.125rem 0;
                transform: rotate(45deg);
            }

            .termsText {
                margin-left: 0.25rem;
            }

            .termsLink {
                color: #333;
                text-decoration: none;
                font-weight: 600;
            }

            .termsLink:hover {
                text-decoration: underline;
            }

            .submitBtn {
                width: 100%;
                padding: 0.6rem;
                background-color: #333;
                color: white;
                border: none;
                border-radius: 0.25rem;
                font-size: 0.95rem;
                font-weight: 600;
                cursor: pointer;
                transition: background-color 0.3s ease;
                margin-top: 0.8rem;
            }

            .submitBtn:hover {
                background-color: #555;
            }

            .socialRegistro {
                margin-top: 1rem;
                text-align: center;
            }

            .socialButtons {
                display: flex;
                justify-content: center;
                gap: 0.8rem;
                margin-top: 0.4rem;
            }

            .socialBtn {
                width: 2.25rem;
                height: 2.25rem;
                border: none;
                border-radius: 50%;
                background-color: #f0f0f0;
                color: #333;
                font-size: 1rem;
                cursor: pointer;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .socialBtn:hover {
                background-color: #ddd;
                transform: scale(1.04);
            }

            .loginPrompt {
                margin-top: 1rem;
                text-align: center;
                font-size: 0.85rem;
                color: #666;
            }

            .loginPrompt a {
                cursor: pointer;
                color: #333;
                text-decoration: none;
                font-weight: 600;
            }

            .loginPrompt a:hover {
                text-decoration: underline;
            }

            .rs-modal {
                font-size: 0.9rem;
            }

            .rs-modal-header {
                padding: 0.75rem 1rem;
            }

            .rs-modal-body {
                padding: 0.75rem 1rem;
            }

            .rs-modal-footer {
                padding: 0.5rem 1rem;
            }
        `}</style>
    </div>);
}

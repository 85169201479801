import React, {useCallback, useState} from 'react'
import {SelectPicker} from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import {Navbar} from "../../layouts";
import {useSelector} from "react-redux";
import {CoachCard} from "../../components";

// Datos de ejemplo
const coaches = [{
    id: 1,
    name: 'Alex Fitness',
    specialty: 'Entrenamiento personal',
    location: 'Ciudad de México',
    certifications: ['NSCA-CSCS'],
    rating: 4.8,
    image: '/images/entrenador.jpg?height=120&width=120'
}, {
    id: 2,
    name: 'María Salud',
    specialty: 'Nutrición deportiva',
    location: 'Guadalajara',
    certifications: ['ISSN'],
    rating: 4.6,
    image: '/images/entrenadora1.jpg?height=120&width=120'
}, {
    id: 3,
    name: 'Carlos Fuerza',
    specialty: 'Levantamiento de pesas',
    location: 'Monterrey',
    certifications: ['USAW'],
    rating: 4.9,
    image: '/images/entrenador2.jpg?height=120&width=120'
},]

const specialties = ['Entrenamiento personal', 'Nutrición deportiva', 'Levantamiento de pesas'].map(item => ({
    label: item, value: item
}))
const locations = ['Ciudad de México', 'Guadalajara', 'Monterrey'].map(item => ({label: item, value: item}))
const certifications = ['NSCA-CSCS', 'ISSN', 'USAW'].map(item => ({label: item, value: item}))

export default function AgendarLlamada() {

    const {isAuthenticated, userRole} = useSelector((state) => state.auth);


    const [filteredCoaches, setFilteredCoaches] = useState(coaches)
    const [filters, setFilters] = useState({
        name: '', specialty: '', location: '', certifications: '',
    })

    const handleFilterChange = useCallback((value, name) => {
        setFilters(prevFilters => ({
            ...prevFilters, [name]: value
        }))
    }, [])

    const applyFilters = useCallback(() => {
        const filtered = coaches.filter(coach => coach.name.toLowerCase().includes(filters.name.toLowerCase()) && (filters.specialty ? coach.specialty === filters.specialty : true) && (filters.location ? coach.location === filters.location : true) && (filters.certifications ? coach.certifications.includes(filters.certifications) : true))
        setFilteredCoaches(filtered)
    }, [filters])

    return (<>
            <Navbar isAuthenticated={isAuthenticated} userRole={userRole}/>
            <div className="agendar-llamada-container">
                <h1 className="filters-title">Agendar Llamada</h1>

                <div className="filters-container">
                    <h2 className="filters-title">Filtros</h2>
                    <div className="filters-grid">
                        <SelectPicker
                            data={coaches.map(coach => ({label: coach.name, value: coach.name}))}
                            placeholder="Nombre del coach"
                            className="filter-input"
                            onChange={(value) => handleFilterChange(value || '', 'name')}
                        />
                        <SelectPicker
                            data={specialties}
                            placeholder="Especialidad"
                            className="filter-input"
                            onChange={(value) => handleFilterChange(value || '', 'specialty')}
                        />
                        <SelectPicker
                            data={locations}
                            placeholder="Lugar"
                            className="filter-input"
                            onChange={(value) => handleFilterChange(value || '', 'location')}
                        />
                        <SelectPicker
                            data={certifications}
                            placeholder="Certificaciones"
                            className="filter-input"
                            onChange={(value) => handleFilterChange(value || '', 'certifications')}
                        />
                        <button
                            onClick={applyFilters}
                            className="apply-filters-button"
                        >
                            Aplicar Filtros
                        </button>
                    </div>
                </div>

                <div className="coaches-list">
                    <CoachCard coach={filteredCoaches[0]}/>

                </div>
            </div>
            <style jsx>{`
                .agendar-llamada-container {
                    max-width: 1024px;
                    margin: 4rem auto 3rem auto;
                    padding: 15px;
                    background-color: white;
                    border-radius: 12px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    transition: box-shadow 0.3s ease;
                }

                .agendar-llamada-container:hover {
                    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
                }

                .filters-container {
                    margin-bottom: 28px;
                    padding: 20px;
                    background-color: #f7fafc;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                }

                .filters-title {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 16px;
                    color: #1a202c;
                }

                .filters-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px;
                }

                .filter-input {
                    width: 100%;
                }

                .apply-filters-button {
                    grid-column: 1 / -1;
                    background-color: #4299e1;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    padding: 12px;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .apply-filters-button:hover {
                    background-color: #2b6cb0;
                }

                .coaches-list {
                    display: grid;
                    gap: 20px;
                }

                .coach-card {
                    display: flex;
                    background-color: white;
                    border: 1px solid #e2e8f0;
                    border-radius: 12px;
                    padding: 20px;
                    transition: all 0.3s ease;
                }

                .coach-card:hover {
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    transform: translateY(-2px);
                }

                .coach-image {
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 20px;
                }

                .coach-info {
                    flex: 1;
                }

                .coach-name {
                    font-size: 20px;
                    font-weight: 600;
                    color: #1a202c;
                    margin-bottom: 4px;
                }

                .coach-specialty {
                    font-size: 16px;
                    color: #4a5568;
                    margin-bottom: 8px;
                }

                .coach-details {
                    font-size: 14px;
                    color: #718096;
                    margin-bottom: 12px;
                }

                .coach-rating {
                    font-size: 14px;
                    color: #4299e1;
                }

                .schedule-button {
                    background-color: #4299e1;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    padding: 10px 16px;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .schedule-button:hover {
                    background-color: #2b6cb0;
                }
            `}</style>
        </>

    )
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const API_URL = 'http://localhost:8080';

const initialState = {
    isRegistering: false, registerSuccess: false, registerError: null,
};

export const registerUser = createAsyncThunk('register/registerUser', async (newUser, {rejectWithValue}) => {
    try {
        const response = await fetch(`${API_URL}/api/users`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify(newUser),
        });

        const data = await response.json();

        if (!response.ok) {
            return rejectWithValue(data.message || 'Registro fallido');
        }

        return data;
    } catch (error) {
        return rejectWithValue(error.message || 'Registro fallido');
    }
});

const userRegisterSlice = createSlice({
    name: 'register', initialState, reducers: {
        clearRegisterState(state) {
            state.isRegistering = false;
            state.registerSuccess = false;
            state.registerError = null;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.isRegistering = true;
                state.registerError = null;
                state.registerSuccess = false;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.isRegistering = false;
                state.registerSuccess = true;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isRegistering = false;
                state.registerError = action.payload;
            });
    },
});

export const {clearRegisterState} = userRegisterSlice.actions;

export default userRegisterSlice.reducer;

import React from 'react';

const TermsOfService = () => {
    return (<div style={styles.container}>
        <h1 style={styles.title}>Terms of Service</h1>
        <p style={styles.paragraph}>
            Welcome to Entrenalo. By using our services, you agree to the following terms and conditions. Please
            read them carefully as they outline your rights and responsibilities when using our platform.
        </p>

        <h2 style={styles.subtitle}>1. Acceptance of Terms</h2>
        <p style={styles.paragraph}>
            By accessing or using Entrenalo.com, you agree to comply with and be bound by these Terms of Service. If
            you do not agree with any part of the terms, you may not use our services.
        </p>

        <h2 style={styles.subtitle}>2. Description of Service</h2>
        <p style={styles.paragraph}>
            Entrenalo connects gym coaches with users for online fitness sessions via video calls. Users can search
            for, book, and pay for coaching services directly through our platform.
        </p>

        <h2 style={styles.subtitle}>3. User Responsibilities</h2>
        <p style={styles.paragraph}>
            As a user, you are responsible for:
        </p>
        <ul style={styles.list}>
            <li>Providing accurate and up-to-date information when registering.</li>
            <li>Maintaining the confidentiality of your account login details.</li>
            <li>Respecting the rights of coaches and other users on the platform.</li>
            <li>Complying with all applicable laws while using Entrenalo.</li>
        </ul>

        <h2 style={styles.subtitle}>4. Coach Responsibilities</h2>
        <p style={styles.paragraph}>
            Coaches must:
        </p>
        <ul style={styles.list}>
            <li>Provide professional and accurate fitness advice during sessions.</li>
            <li>Respect the privacy and personal information of users.</li>
            <li>Follow the guidelines and terms set by Entrenalo.</li>
        </ul>

        <h2 style={styles.subtitle}>5. Payments and Refunds</h2>
        <p style={styles.paragraph}>
            Payments for coaching sessions are processed through secure third-party services. All transactions are
            final, and refunds will only be provided under exceptional circumstances, as outlined in our refund
            policy.
        </p>

        <h2 style={styles.subtitle}>6. Termination</h2>
        <p style={styles.paragraph}>
            Entrenalo reserves the right to suspend or terminate your account if you violate any of these terms or
            engage in any unlawful activity on the platform.
        </p>

        <h2 style={styles.subtitle}>7. Limitation of Liability</h2>
        <p style={styles.paragraph}>
            Entrenalo is not liable for any indirect, incidental, or consequential damages arising from the use of
            our platform or services. Your use of the platform is at your own risk.
        </p>

        <h2 style={styles.subtitle}>8. Changes to Terms</h2>
        <p style={styles.paragraph}>
            We may update these Terms of Service from time to time. Any changes will be posted on this page, and it
            is your responsibility to review the terms periodically.
        </p>

        <h2 style={styles.subtitle}>9. Contact Us</h2>
        <p style={styles.paragraph}>
            If you have any questions about these Terms of Service, please contact us at: support@entrenalo.com
        </p>
    </div>);
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
    }, title: {
        fontSize: '36px', fontWeight: 'bold', marginBottom: '20px', color: '#222',
    }, subtitle: {
        fontSize: '24px', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px',
    }, paragraph: {
        marginBottom: '20px', fontSize: '16px', color: '#555',
    }, list: {
        listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px',
    },
};

export default TermsOfService;
